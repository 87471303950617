import React from "react";
import { Link } from "react-router-dom";
import { Icon } from "@iconify/react/dist/iconify.js";

const data = [
  {
    title: "Regulators",
    items: [
      {
        label: "BCAS",
        icon: "ion:link",
        link: "https://bcasindia.gov.in/#!/hi_home",
      },
      {
        label: "DGCA",
        icon: "ion:link",
        link: "https://www.dgca.gov.in/digigov-portal/",
      },
    ],
  },
  {
    title: "Information",
    items: [
      {
        label: "Apply for NOC Height Clearance",
        icon: "ion:link",
        link: "https://nocas2.aai.aero/nocas/",
      },
      {
        label: "Information about Drones",
        icon: "ion:link",
        link: "https://digitalsky.dgca.gov.in/home",
      },
    ],
  },
];


const InfoCard = ({ title, items }) => (
  <div className="bg-sf-secondary gap-4 rounded-lg w-full md:w-[45%] p-6 font-sans border border-stone-200 flex flex-col justify-start">
    <h2 className="text-base uppercase font-semibold text-br-primary">{title}</h2>
    <div className="flex flex-col gap-4">
      {items.map((item, index) => (
        <div key={index} className="flex flex-col gap-2">
          <h3 className="text-base font-semibold text-tx-primary">{item.label}</h3>
          <div className="text-base flex flex-row gap-2 text-tx-secondary items-center">
            <Icon icon={item.icon} className="text-base text-tx-primary" />
            <Link className="text-br-primary font-medium" target="_blank" to={item.link}>
              Check here
            </Link>
          </div>
        </div>
      ))}
    </div>
  </div>
);

const RegulatoryPage = () => {
  return (

    <div className="flex flex-col font-sans items-center justify-center gap-8">

      <div className="flex flex-col gap-2 w-fit items-center text-center">
        <div className="text-lg text-tx-primary font-semibold md:text-xl">
          Regulatory Information

        </div>
        <div className="h-0.5 w-10/12 bg-br-primary rouded-full"></div>
      </div>

      <div className="flex w-full justify-center flex-col md:flex-row flex-wrap gap-6 md:gap-10">
        {data.map((section, index) => (
          <InfoCard key={index} title={section.title} items={section.items} />
        ))}
      </div>

    </div>
    // <div className="h-full flex flex-col justify-center items-center mx-auto gap-y-10 mb-20">
    //   <div className="flex flex-col gap-y-[2px] items-center text-center">
    //     <div className="font-semibold text-lg md:text-2xl">
    //       Regulatory Information
    //     </div>
    //     <div className="bg-[#003A6F] w-[100%] h-[2px] rounded-full"></div>
    //   </div>
    //   <div className="flex font-vietnam flex-col items-center w-full h-full gap-y-10">
    //     <div className="flex w-full flex-col md:flex-row items-center justify-center gap-y-10 gap-x-10">
    //       <div className="bg-[#F5F5F5] gap-y-5  rounded-lg w-[80%] md:w-[25%] p-3 font-vietnam border-2 border-[#e9e9e9] flex flex-col  justify-start">
    //         <div className="text-xl font-bold text-[#003A6F]">Regulators </div>
    //         <div className="flex flex-col gap-y-2">
    //           <div className="flex flex-col">
    //             <div className="text-lg font-semibold text-black">BCAS</div>
    //             <div className="text-base flex flex-row gap-x-4 text-gray-400">
    //               <"ion:link" />
    //               <Link
    //                 className="text-blue"
    //                 target="_blank"
    //                 to={"https://bcasindia.gov.in/#!/hi_home"}
    //               >
    //                 Check here
    //               </Link>
    //             </div>
    //           </div>

    //           <div className="flex flex-col">
    //             <div className="text-lg font-semibold text-black">DGCA</div>
    //             <div className="text-base flex flex-row gap-x-2 text-gray-400">
    //               <"ion:link" />
    //               <Link
    //                 className="text-blue"
    //                 target="_blank"
    //                 to={"https://www.dgca.gov.in/digigov-portal/"}
    //               >
    //                 Check here
    //               </Link>
    //             </div>
    //           </div>
    //         </div>
    //       </div>
    //       <div className="bg-[#F5F5F5] gap-y-5  rounded-lg w-[80%] md:w-[25%] p-3 font-vietnam border-2 border-[#e9e9e9] flex flex-col  justify-start">
    //         <div className="text-xl font-bold text-[#003A6F]">Information</div>
    //         <div className="flex flex-col gap-y-2">
    //           <div className="flex flex-col">
    //             <div className="text-lg font-semibold text-black">
    //               Apply for NOC Height Clearance
    //             </div>
    //             <div className="text-base flex flex-row gap-x-2 text-gray-400">
    //               <Phone />

    //               <Link
    //                 className="text-blue"
    //                 target="_blank"
    //                 to={"https://nocas2.aai.aero/nocas/"}
    //               >
    //                 Check here
    //               </Link>
    //             </div>
    //           </div>
    //           <div className="flex flex-col">
    //             <div className="text-lg font-semibold text-black">
    //               Information about Drones
    //             </div>
    //             <div className="text-base flex flex-row gap-x-2 text-gray-400">
    //               <Drone />
    //               <Link
    //                 className="text-blue"
    //                 target="_blank"
    //                 to={"https://digitalsky.dgca.gov.in/home"}
    //               >
    //                 Check here
    //               </Link>
    //             </div>
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </div>
  );
};

export default RegulatoryPage;
