import React from "react";

const Globe = () => {
  return (
    <svg
      width="231"
      height="300"
      viewBox="0 0 231 300"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_139_3325)">
        <path
          d="M148.668 291.52C153.196 300.743 175.041 299.588 195.305 293.138C205.471 289.897 214.518 285.538 220.971 280.095C226.099 275.764 228.871 270.148 226.846 266.646C221.574 257.588 200.258 259.113 180.501 265.383C161.433 271.448 144.811 283.582 148.668 291.52Z"
          stroke="black"
          stroke-width="1.5"
          stroke-miterlimit="10"
        />
        <path
          d="M108.063 287.56C111.544 295.272 124.354 299.265 139.327 299.824C157.407 300.49 177.711 297.04 197.896 290.613C217.682 284.305 235.234 275.857 247.642 265.344C257.408 257.059 262.477 246.282 258.41 239.795C253.712 232.334 240.645 229.127 225.714 228.87C208.146 228.574 188.809 231.97 169.649 238.037C150.812 244.009 133.872 251.976 121.334 261.968C111.162 270.083 104.883 280.479 108.063 287.56Z"
          stroke="black"
          stroke-width="1.5"
          stroke-miterlimit="10"
        />
        <path
          d="M70.6583 272.675C72.778 277.721 78.1257 281.861 84.9188 284.553C92.881 287.703 102.786 289.243 113.892 289.628C126.141 290.049 139.417 289.031 153.291 286.833C167.74 284.543 182.347 281.042 196.708 276.474C210.921 271.95 224.44 266.527 236.826 260.262C248.456 254.377 258.706 247.956 266.984 240.973C274.29 234.806 279.867 228.341 282.529 221.83C284.799 216.267 284.504 210.217 281.547 205.837C278.319 201.068 272.205 197.55 264.855 195.279C256.424 192.677 246.438 191.554 235.486 191.477C211.016 191.313 184.191 196.121 157.589 204.518C131.421 212.785 107.823 223.724 90.1382 237.423C75.7479 248.582 66.5301 262.802 70.6583 272.675Z"
          stroke="black"
          stroke-width="1.5"
          stroke-miterlimit="10"
        />
        <path
          d="M39.4493 247.908C41.8208 253.857 48.1397 258.747 56.2072 261.887C65.7316 265.589 77.6351 267.35 91.0289 267.735C105.854 268.156 121.954 266.851 138.813 264.134C156.393 261.299 174.181 257.017 191.684 251.463C209 245.965 225.465 239.405 240.547 231.853C254.678 224.774 267.115 217.083 277.144 208.739C285.953 201.406 292.664 193.747 295.819 186.05C298.49 179.522 298.029 172.403 294.401 167.356C290.403 161.806 282.971 157.82 274.084 155.279C263.874 152.364 251.845 151.194 238.668 151.245C209.258 151.367 177.112 157.258 145.188 167.298C129.31 172.294 114.077 178.206 99.886 185.002C86.3835 191.47 74.1891 198.547 63.8752 206.302C54.5664 213.306 47.033 220.658 42.4723 228.334C38.5695 234.913 37.1727 242.179 39.4493 247.908Z"
          stroke="black"
          stroke-width="1.5"
          stroke-miterlimit="10"
        />
        <path
          d="M17.0255 215.107C19.4717 221.4 26.2691 226.545 34.9585 229.799C45.2916 233.663 58.2283 235.421 72.824 235.71C89.0253 236.027 106.637 234.489 125.109 231.441C144.391 228.258 163.912 223.531 183.139 217.45C202.156 211.431 220.243 204.294 236.821 196.112C252.329 188.455 265.975 180.174 276.993 171.212C286.631 163.367 293.981 155.212 297.441 147.013C300.342 140.123 299.815 132.57 295.859 127.325C291.457 121.501 283.34 117.428 273.661 114.871C262.507 111.929 249.411 110.852 235.065 111.063C219.539 111.294 203.056 113.058 186.021 116.064C168.438 119.17 150.744 123.525 133.308 128.988C116.012 134.41 99.4016 140.785 83.8959 148.082C69.1418 155.027 55.7903 162.599 44.4424 170.881C34.209 178.354 25.8852 186.169 20.7492 194.352C16.3816 201.319 14.6758 209.041 17.0255 215.107Z"
          stroke="black"
          stroke-width="1.5"
          stroke-miterlimit="10"
        />
        <path
          d="M5.3432 176.82C7.72966 182.904 14.5069 187.808 23.1451 190.857C33.4854 194.501 46.4124 196.057 61.0208 196.184C77.2648 196.321 94.9231 194.646 113.463 191.502C132.825 188.216 152.436 183.434 171.771 177.343C190.893 171.315 209.09 164.216 225.796 156.116C241.411 148.542 255.165 140.389 266.313 131.584C276.035 123.9 283.482 115.953 287.048 107.943C290.007 101.28 289.554 93.9188 285.657 88.9058C281.281 83.2876 273.186 79.4566 263.537 77.0912C252.367 74.3579 239.269 73.4609 224.904 73.8173C209.336 74.2063 192.81 76.0894 175.717 79.1784C158.068 82.3704 140.299 86.7709 122.775 92.242C105.392 97.671 88.6881 104.014 73.0724 111.241C58.2226 118.115 44.7714 125.579 33.3018 133.722C22.98 141.053 14.5614 148.685 9.30634 156.688C4.87231 163.449 3.05676 170.971 5.3432 176.82Z"
          stroke="black"
          stroke-width="1.5"
          stroke-miterlimit="10"
        />
        <path
          d="M5.52353 136.127C7.73861 141.516 14.0032 145.759 21.9361 148.351C31.4806 151.464 43.3597 152.686 56.7884 152.637C71.7284 152.58 87.9525 150.908 104.993 147.934C122.787 144.826 140.813 140.398 158.602 134.817C176.197 129.294 192.958 122.836 208.381 115.502C222.797 108.644 235.522 101.293 245.899 93.3703C254.936 86.4666 261.904 79.3646 265.347 72.1715C268.175 66.2485 267.916 59.6463 264.447 55.22C260.52 50.2216 253.147 46.8846 244.343 44.8594C234.092 42.5055 222.058 41.8214 208.829 42.2644C179.153 43.2663 146.748 49.5834 114.346 59.6648C98.2342 64.6807 82.7485 70.5061 68.2613 77.1125C54.501 83.3898 42.0376 90.1741 31.3971 97.5533C21.8514 104.176 14.0634 111.035 9.18457 118.228C5.10404 124.251 3.41606 130.981 5.52353 136.127Z"
          stroke="black"
          stroke-width="1.5"
          stroke-miterlimit="10"
        />
        <path
          d="M17.7104 96.3993C19.6343 100.716 24.9112 104.006 31.534 105.983C39.5252 108.363 49.402 109.205 60.5557 109.027C72.9547 108.826 86.3934 107.335 100.502 104.804C115.226 102.16 130.14 98.4706 144.871 93.8679C159.444 89.313 173.344 84.0209 186.172 78.0379C198.171 72.4393 208.798 66.458 217.528 60.0215C225.133 54.4115 231.048 48.6683 234.09 42.8158C236.569 38.0392 236.55 32.6662 233.801 29.0974C230.676 25.0496 224.638 22.3845 217.402 20.7921C208.92 18.9289 198.93 18.4469 187.911 18.8884C163.091 19.8895 135.921 25.262 108.685 33.7146C81.9137 42.0297 57.7189 52.4336 39.0704 65.1409C31.1123 70.5678 24.6331 76.1579 20.5935 82.0133C17.2465 86.8707 15.9006 92.3251 17.7104 96.3993Z"
          stroke="black"
          stroke-width="1.5"
          stroke-miterlimit="10"
        />
        <path
          d="M40.9989 61.0057C42.4787 63.9926 46.329 66.1826 51.1141 67.4804C56.8889 69.0436 63.9649 69.5335 71.937 69.317C90.365 68.8114 111.013 64.7823 131.86 58.2936C152.27 51.9357 170.409 44.0472 183.764 34.5207C193.932 27.2591 199.798 17.8074 196.127 12.8146C191.508 6.56359 178.579 5.07012 163.615 5.72645C145.721 6.51774 126.062 10.4413 106.308 16.562C86.9051 22.5785 69.411 30.044 55.9776 39.1114C45.3687 46.2792 38.1868 55.3013 40.9989 61.0057Z"
          stroke="black"
          stroke-width="1.5"
          stroke-miterlimit="10"
        />
        <path
          d="M73.4886 32.9877C75.3378 36.3049 82.0023 37.2883 89.8025 37.0074C99.35 36.6602 109.995 34.5522 120.74 31.2169C131.269 27.9463 140.661 23.9113 147.669 19.0457C153.041 15.3115 156.304 10.4879 154.508 7.86C152.276 4.61125 145.565 3.82181 137.764 4.16976C128.362 4.59258 117.987 6.67084 107.539 9.90628C87.8523 16.0096 69.8831 26.4592 73.4886 32.9877Z"
          stroke="black"
          stroke-width="1.5"
          stroke-miterlimit="10"
        />
        <path
          d="M112.434 14.7588C74.7726 26.4482 41.6504 53.0275 22.55 87.0178C3.79075 120.461 -1.02802 161.114 9.75024 196.467C20.4362 231.412 46.5741 261.41 79.5403 277.378C112.873 293.49 153.225 295.816 189.305 284.345"
          stroke="black"
          stroke-width="1.5"
          stroke-miterlimit="10"
        />
        <path
          d="M112.433 14.7588C78.4526 19.1505 47.6761 38.8186 31.3615 66.6709C14.8822 94.8642 12.1554 131.627 22.3222 165.283C43.6175 235.509 118.718 289.889 189.305 284.345"
          stroke="black"
          stroke-width="1.5"
          stroke-miterlimit="10"
        />
        <path
          d="M112.433 14.7587C102.408 12.828 92.4288 14.3594 84.9607 19.3196C77.3361 24.3927 71.6732 33.1916 68.4115 43.5382C64.7991 55.0175 63.7567 68.4993 64.6364 82.9427C65.5873 98.4852 68.7362 114.836 73.6293 131.457C83.8767 166.23 100.768 199.922 122.47 228.774C132.814 242.518 143.97 254.672 155.783 264.532C166.737 273.67 178.071 280.668 189.305 284.345"
          stroke="black"
          stroke-width="1.5"
          stroke-miterlimit="10"
        />
        <path
          d="M112.434 14.7587C112.164 10.311 113.445 7.82505 114.411 8.85373C116.807 11.414 118.968 16.9799 121.837 24.4735C125.44 33.8899 129.248 45.2276 133.352 58.2102C137.889 72.5606 142.514 88.1338 147.209 104.666C157.086 139.451 166.346 175.054 174.405 209.38C178.154 225.35 181.445 240.243 184.15 253.827C186.537 265.815 188.355 276.048 189.305 284.345"
          stroke="black"
          stroke-width="1.5"
          stroke-miterlimit="10"
        />
        <path
          d="M112.434 14.7587C131.001 0.811709 157.254 1.24027 176.063 14.918C197.687 30.6765 213.345 59.3585 222.348 92.2073C231.926 127.217 233.764 165.281 227.685 201.258C221.934 235.196 209.215 265.71 189.305 284.345"
          stroke="black"
          stroke-width="1.5"
          stroke-miterlimit="10"
        />
        <path
          d="M112.434 14.7587C146.106 1.10518 184.683 2.1799 215.207 17.8468C245.601 33.4808 268.592 63.6551 277.766 97.979C296.614 168.819 256.045 254.377 189.305 284.345"
          stroke="black"
          stroke-width="1.5"
          stroke-miterlimit="10"
        />
        <path
          d="M112.434 14.7587C150.544 6.28615 192.506 12.9963 226.617 32.9033C260.334 52.6147 285.927 85.2405 295.524 121.344C304.876 156.642 298.539 195.542 278.827 225.42C258.963 255.473 225.695 276.969 189.305 284.345"
          stroke="black"
          stroke-width="1.5"
          stroke-miterlimit="10"
        />
        <path
          d="M112.434 14.7586C127.485 14.6508 143.403 18.3751 158.852 25.1961C175.124 32.3877 190.793 42.9064 205.062 55.8825C234.539 82.715 256.707 118.448 266.883 156.049C271.72 173.953 273.64 191.875 272.171 208.624C270.777 224.442 266.266 239.211 258.742 251.251C244.582 273.865 216.962 286.452 189.305 284.345"
          stroke="black"
          stroke-width="1.5"
          stroke-miterlimit="10"
        />
        <path
          d="M112.434 14.7588C118.986 19.394 125.58 27.4049 132.503 37.5832C140.55 49.4188 148.438 63.5231 156.232 79.3725C173.304 114.104 187.825 152.44 198.582 190.782C203.605 208.697 207.461 225.578 209.822 241.059C211.9 254.699 212.778 266.542 211.681 276.086C210.826 283.505 208.316 289.499 204.296 290.902C200.263 292.3 194.609 289.288 189.305 284.345"
          stroke="black"
          stroke-width="1.5"
          stroke-miterlimit="10"
        />
        <path
          d="M112.434 14.7588C106.893 22.3417 103.108 33.2339 100.517 46.0793C97.5764 60.6865 96.3177 77.2676 96.3896 95.2055C96.5547 134.128 102.612 174.883 113.751 213.001C118.972 230.853 125.107 247.164 132.265 261.283C138.651 273.872 145.59 284.275 153.282 291.27C159.67 297.071 167.068 300.223 173.299 298.952C179.58 297.663 185.26 291.954 189.305 284.345"
          stroke="black"
          stroke-width="1.5"
          stroke-miterlimit="10"
        />
        <path
          d="M112.434 14.7588C82.9291 30.5117 59.1063 61.4406 46.3257 97.9171C33.1303 135.653 31.7526 177.785 42.9058 214.796C53.4019 249.539 75.7388 278.675 103.792 291.58C130.457 303.812 163.384 300.498 189.305 284.345"
          stroke="black"
          stroke-width="1.5"
          stroke-miterlimit="10"
        />
        <path
          d="M112.434 14.7588C74.7726 26.4482 41.6504 53.0275 22.55 87.0178C3.79075 120.461 -1.02802 161.114 9.75024 196.467C20.4362 231.412 46.5741 261.41 79.5403 277.378C112.873 293.49 153.225 295.816 189.305 284.345"
          stroke="black"
          stroke-width="1.5"
          stroke-miterlimit="10"
        />
      </g>
      <defs>
        <clipPath id="clip0_139_3325">
          <rect width="299.862" height="300" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Globe;
