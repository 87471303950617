import React from "react";
import { Link } from "react-router-dom";
import { Icon } from "@iconify/react/dist/iconify.js";
const TrainCard = ({ data, start, end }) => {
  // const [data, setData] = useState([]);
  // const currentDate = new Date().toISOString().split("T")[0];
  const splitDayAndDate = new Date().toString().split(" ");
  const date = splitDayAndDate.slice(2, 3);
  const Month = splitDayAndDate.slice(1, 2);
  const MonthAndDate = splitDayAndDate.slice(1, 3).join(",");
  // const DayAndRest = MonthAndDate.console.log("new Date is", MonthAndDate);
  // const options = useMemo(
  //   () => ({
  //     method: "GET",
  //     url: "http://3.110.29.109:3000/trains/gettrainon",
  //     params: {
  //       from: `${from}`,
  //       to: `${to}`,
  //       date: `${currentDate}`,
  //     },
  //   }),
  //   [from, to, currentDate]
  // );
  // const fetchData = useCallback(async () => {
  //   try {
  //     const response = await axios.request(options);
  //     setData(response.data.data);
  //     console.log(response.data);
  //   } catch (error) {
  //     console.error(error);
  //   }
  // }, [options]);
  // useEffect(() => {
  //   fetchData();
  // }, [fetchData]);
  const calculateToDate = (travelTime) => {
    var toDate = parseInt(date) + Math.floor(travelTime / 24);
    // console.log("travelTime", travelTime, "date", date);
    toDate = Month + "," + toDate.toString();
    return toDate;
  };
  if (!Array.isArray(data) || data.length === 0) {
    return <div></div>;
  }

  return (
    <>
      {data.length > 0 ? (
        data.slice(start, end).map((train, index) => (
          <div
            key={index}
            className="relative w-full justify-center h-auto font-sans overflow-hidden
              border-stone-300 border rounded-xl flex flex-col bg-sf-secondary">

            {/* Train name & irctc button */}
            <div className="relative flex flex-row items-center justify-between px-4 py-3 border-b border-stone-300">

              <div className="flex flex-col gap-3 text-sm md:flex-row text-lg font-sans text-br-primary">
                <p className="font-bold">{train.train_base.train_name}</p>
                <p className="font-bold">{train.train_base.train_no}</p>
              </div>

              <Link
                to={"https://www.irctc.co.in/nget/train-search"}
                target="_blank"
              >
                <button className="flex flex-row bg-br-primary text-white px-3 py-1.5 gap-2 rounded-lg items-center justify-center font-medium text-xs ">
                  visit IRCTC
                  <Icon icon="ion:arrow-up-right-box" className="text-xs" />
                </button>
              </Link>

            </div>

            {/* Junction Details */}
            <div className="flex flex-row gap-5 px-4 py-3">

              {/* From station details */}
              <div className="flex flex-col gap-1.5 justify-start">
                <h3 className="text-sm font-medium text-tx-primary lg:text-base">
                  {train.train_base.from_stn_name}
                </h3>
                <div className="flex flex-col gap-1.5 justify-between md:flex-row">
                  <p
                    className="px-2 py-1 rounded-lg text-tx-secondary border bg-sf-primary border-stone-300 
                      w-fit text-sm font-medium">
                    {MonthAndDate}
                  </p>
                  <p className="px-2 py-1 rounded-lg text-tx-secondary border bg-sf-primary border-stone-300 
                      w-fit text-sm font-medium">
                    {train.train_base.from_time}
                  </p>
                </div>
              </div>

              {/* Train icon */}
              <div className="flex flex-row items-center justify-center gap-1.5 flex-grow flex-1">
                <hr className="w-full bg-stone-500" />
                <Icon icon="hugeicons:speed-train-01" className="text-2xl text-stone-500" />
              </div>

              {/* To station details */}
              <div className="flex flex-col gap-1.5 items-end">

                <h3 className="text-sm font-medium text-tx-primary lg:text-base">
                  {train.train_base.to_stn_name}{" "}
                </h3>

                <div className="flex flex-col gap-1.5 justify-between md:flex-row">
                  <p
                    className="px-2 py-1 rounded-lg text-tx-secondary border bg-sf-primary border-stone-300 
                      w-fit text-sm font-medium">
                    {calculateToDate(train.train_base.travel_time)}
                  </p>
                  <p
                    className="px-2 py-1 rounded-lg text-tx-secondary border bg-sf-primary border-stone-300 
                      w-fit text-sm font-medium">
                    {train.train_base.to_time}
                  </p>
                </div>
              </div>
            </div>

          </div>
        ))
      ) : (
        <p>Loading...</p>
      )}
    </>
  );
};

export default TrainCard;
