import React, { useMemo, useState, useCallback, useEffect } from "react";
import TrainCard from "./TrainCard";
import axios from "axios";
import Multiselect from "multiselect-react-dropdown";
import { Pagination, Stack } from "@mui/material";
import { stationList } from "./StationList";
import { Icon } from "@iconify/react/dist/iconify.js";

const TrainSection = () => {
  // const stationList = [
  //   { station: "KLBG", name: "Kalaburagi" },
  //   { station: "SBC", name: "Bangalore" },
  //   { station: "PUNE", name: "Pune" },
  //   { station: "NDLS", name: "New Delhi" },
  // ];
  const [showTrainCard, setShowTrainCard] = useState(false);
  const [data, setData] = useState([]);
  const currentDate = new Date().toISOString().split("T")[0];

  const [fromValue, setFromValue] = useState("KLBG");
  const [toValue, setToValue] = useState("PUNE");

  // const handleFromChange = (e) => {
  //   setFromValue(e.target.value);
  // };
  const openGoogleMaps = (link) => {
    window.open(link, "_blank");
  };
  const handleToChange = (e) => {
    setToValue(e.target.value);
  };
  const options = useMemo(
    () => ({
      method: "GET",
      url: "https://mts-backend.azurewebsites.net/trains/gettrainon",
      params: {
        from: `${fromValue}`,
        to: `${toValue}`,
        date: `${currentDate}`,
      },
    }),
    [fromValue, toValue, currentDate]
  );
  const fetchData = useCallback(async () => {
    try {
      const response = await axios.request(options);
      setData(response.data.data);
      // console.log(response.data);
    } catch (error) {
      console.error(error);
    }
  }, [options]);
  const handleFindTrainClick = () => {
    if (toValue.trim() !== "") {
      setShowTrainCard(true);
      fetchData();
    } else {
      setShowTrainCard(false);
    }
  };
  const [page, setPage] = useState(1); // Current page
  const itemsPerPage = 3; // Number of items per page

  const startIndex = (page - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const handleChangePage = (event, value) => {
    setPage(value);
  };
  useEffect(() => {
    handleFindTrainClick();
  }, []);
  return (
    <div className="flex flex-col items-center justify-center w-full py-12 gap-12">
      {/* Note card */}
      <div
        className="flex flex-col md:flex-row text-tx-primary w-full md:w-[90%] font-sans md:items-center justify-between gap-6 
        border border-stone-200 p-4 rounded-3xl bg-sf-secondary"
      >
        <div className="bg-slate-200 p-3 w-fit rounded-2xl border border-stone-200">
          <Icon icon="ion:footsteps" className="text-2xl text-br-primary" />
        </div>
        <p className="leading-relaxed">
          <span className="font-semibold">Note:</span>
          The Distance Between Kalaburagi Airport and Railway Station is
          Approximately
          <b className="text-br-primary">{" 16 km"}</b>.
        </p>
        <button
          onClick={() =>
            openGoogleMaps(
              "https://www.google.com/maps/dir/Kalaburagi+Airport,+Kalaburagi+Airport+Road,+Srinivas+Saradgi,+Karnataka/Kalaburagi+Railway+Station,+Pedestrian+Overpass,+Ghouse+Nagar,+Tarfile,+Kalaburagi,+Karnataka+585102/@17.3183114,76.8483377,13z/data=!3m1!4b1!4m13!4m12!1m5!1m1!1s0x3bc8bf9b577dd5e9:0x37ace0c5682df26d!2m2!1d76.9533712!2d17.310407!1m5!1m1!1s0x3bc8c1a3c26c29f3:0xce30eee0198b070a!2m2!1d76.8262234!2d17.3147019?entry=ttu"
            )
          }
          className="text-br-primary text-sm px-3 hover:text-slate-600 uppercase font-semibold"
        >
          Maps
        </button>
      </div>

      <div className="flex flex-col items-center justify-center w-full gap-5">
        {/* search section */}
        <div className="flex flex-wrap w-full md:w-fit flex-row items-center justify-between md:justify-center gap-4 md:justify-between">
          {/* from and to */}
          <div className="bg-sf-secondary w-full md:w-fit rounded-2xl p-1 shadow-sm flex flex-row flex-wrap justify-between gap-1 md:gap-3 items-center  border border-stone-200  justify-center shrink-0  relative md:justify-start">
            {/* <div className="flex flex-row items-center justify-center rounded-xl 
          border border-stone-200 bg-sf-secondary overflow-hidden">
          <span className="text-base px-3 font-tx-secondary font-medium">
          From:
          </span>
          <input
            name="from"
            value={fromValue}
            // onChange={handleFromChange}
            className="px-3 py-2 bg-sf-primary "
            type="text"
            placeholder="Write something here..."
            />
            </div> */}

            <p className="bg-white font-sans font-medium rounded-xl px-4 py-2.5 border border-stone-200 flex flex-row items-center justify-center">
              {" "}
              Kalaburagi{" "}
            </p>

            <Icon
              icon="ion:trail-sign"
              className="text-xl text-br-primary mx-4 md:mx-0"
            />

            <div className="bg-white font-sans font-medium rounded-xl px-2 h-full border border-stone-200 flex-1 flex flex-row items-center justify-between md:justify-center">
              <span className="text-base pr-2 text-tx-secondary font-medium">
                To:
              </span>
              <input
                name="to"
                value={toValue}
                onChange={handleToChange}
                className="ml-1 border-2 rounded-r-xl py-2 w-full"
                type="text"
                placeholder="Where?"
              />
              {/* <Multiselect
                className="border-none bg-sf-primary rounded-xl "
                placeholder="where?"
                onSelect={(e) => setToValue(e[0].station)}
                onRemove={(e) => setToValue("")}
                options={stationList}
                displayValue="name"
                selectionLimit={1}
              /> */}
            </div>
          </div>

          {/* date */}
          <div className="bg-sf-secondary rounded-2xl p-1 shadow-sm flex flex-row gap-3 items-center  border border-stone-200  justify-center shrink-0  relative md:justify-start">
            <div className="bg-white font-sans font-medium rounded-xl gap-3 px-4 py-2.5 border border-stone-200 flex flex-row items-center justify-center">
              <Icon icon="ion:calendar" className="text-xl text-tx-secondary" />
              <p className="text-base text-tx-secondary font-medium">
                {currentDate}
              </p>
            </div>
          </div>

          {/* search button */}
          <button
            onClick={handleFindTrainClick}
            className="flex flex-row items-center justify-center bg-br-primary rounded-2xl p-3.5 shadow-sm border border-stone-200 text-white font-semibold w-fit"
          >
            <Icon icon="ion:search" className="text-xl text-white" />
          </button>
        </div>

        {/* note 2 */}
        <div className="w-full flex flex-row flex-wrap items-center justify-center gap-1.5">
          <p className="text-gray-600 text-base">
            Please enter your destination code in the To Where input. Refer the
          </p>
          <a
            href="https://icf.indianrailways.gov.in/PB/pass/stations.html"
            target="_blank"
            rel="noopener noreferrer"
            className="text-base text-br-primary underline"
          >
            Station Code List
          </a>
        </div>
      </div>

      {/* Trains */}
      <div className="flex flex-col w-full items-center just gap-10">
        <div className="flex flex-row w-full items-end justify-between border-b-2 pb-5 border-stone-200">
          <h2 className="font-sans font-semibold text-br-primary text-xl">
            Available Trains
          </h2>

          <Stack direction="row" spacing={2} justifyContent="center">
            <Pagination
              variant="outlined"
              shape="rounded"
              count={Math.ceil(data.length / itemsPerPage)}
              page={page}
              onChange={handleChangePage}
            />
          </Stack>
        </div>

        {showTrainCard && (
          <div className="flex flex-col w-full flex-wrap gap-5">
            {data.length > 0 ? (
              <TrainCard data={data} start={startIndex} end={endIndex} />
            ) : (
              <div>No trains found</div>
            )}
          </div>
        )}

        <div className="mt-4 mb-2">
          <Stack direction="row" spacing={2} justifyContent="center">
            <Pagination
              variant="outlined"
              shape="rounded"
              count={Math.ceil(data.length / itemsPerPage)}
              page={page}
              onChange={handleChangePage}
            />
          </Stack>
        </div>

        <button className="flex flex-row w-fit gap-3 bg-black text-white font-sans px-5 py-3 rounded-xl hover:scale-105">
          <a
            href="https://www.irctc.co.in/nget/train-search"
            target="_blank"
            style={{ textDecoration: "none", color: "white" }}
          >
            Book on IRCTC
          </a>
          <Icon icon="ion:arrow-up-right-box" className="text-lg" />
        </button>
      </div>
    </div>
  );
};

export default TrainSection;
