import { BrowserRouter, Routes, Route } from "react-router-dom";
import Navbar from "./components/navbar";
import Footer from "./components/footer";
import Landing from "./components//Landing/landing";
import Flight from "./components/Flight/Flight";
import Transport from "./components/Transport";
import Discover from "./components/Discover/Discover";
import Blog from "./components/Blogs/AllBlogs.js";
import Parking from "./components/Parking/Parking.js";
import BlogPage from "./components/Blogs/BlogPage.js";
import ParkCheck from "./components/Parking/CheckParking";
import TourismBlogs from "./components/Blogs/TourismBlogs";
import About from "./components/AboutUs/About.js";
import Devpage from "./components/DevPage/devpage.js";

function App() {
  return (
    <div>
      <BrowserRouter>
        <Navbar />
        <Routes>
          <Route path="/" element={<Landing />} />

          <Route path="/flights" element={<Flight />} />
          <Route path="/transport" element={<Transport />} />
          <Route path="/discover" element={<Discover />} />
          <Route path="/blogs" element={<Blog />} />
          <Route path="/blogs/:slug" element={<BlogPage />} />
          {/* <Route path="/parking" element={<Parking />} />
          <Route path="/checkparking" element={<ParkCheck />} /> */}
          <Route path="/blogPage" element={<BlogPage />} />
          <Route path="/Tourism" element={<TourismBlogs />} />
          <Route path="/About" element={<About />} />
          <Route path="/meetdevelopers" element={<Devpage />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  );
}
export default App;
