import React from "react";

// rename
const Intro = (props) => {
    return (
        <section className="relative w-full overflow-x-hidden">

            <div className="absolute top-0 left-0 w-full z-0 h-full">
                <img src={props.image} alt="" className="w-full h-full object-cover" />
            </div>

            <div className="relative flex flex-col gap-6 h-full pt-28 md:pt-60 p-8 md:pb-16 z-10 md:px-16">

                {/* Into Text */}
                <div className="flex flex-col gap-2 md:gap-4">
                    <h2 className="text-2xl md:text-3xl text-br-primary font-semibold font-sans leading-loose">
                        {props.title}
                    </h2>
                    <p className="text-base md:text-lg font-sans font-medium text-tx-primary w-full md:w-[70%]">
                        {props.description}
                    </p>
                </div>

            </div>
        </section>
    );
};

export default Intro;
