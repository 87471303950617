import { Icon } from "@iconify/react/dist/iconify.js";
import React, { useState, useEffect } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import logo from "../assets/Images/aai_logo.png";

const Navbar = () => {
  const [toggleNav, setToggleNav] = useState(false);
  const [activeLink, setActiveLink] = useState("Home");
  const location = useLocation();

  const handleNavToggle = () => {
    setToggleNav(!toggleNav);
  };

  const handleLinkClick = (link) => {
    setActiveLink(link);
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 768) {
        setToggleNav(false);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (location.pathname === "/checkparking") {
      setActiveLink("Parking");
    } else if (location.pathname === "/parking") {
      setActiveLink("Parking");
    } else if (location.pathname === "/flights") {
      setActiveLink("Flights");
    } else if (
      location.pathname === "/discover" ||
      location.pathname.includes("/blogs") ||
      location.pathname.includes("/Tourism")
    ) {
      setActiveLink("Discover");
    } else if (location.pathname === "/transport") {
      setActiveLink("Transport");
    } else if (location.pathname === "/About") {
      setActiveLink("About Us");
    } else {
      setActiveLink(activeLink);
    }
  }, [location.pathname]);

  const NavigationLink = ({ text, path, icon, activeLink }) => {
    return (
      <Link
        to={path}
        className={`${
          activeLink === text
            ? "text-br-primary no-underline"
            : "text-tx-secondary no-underline"
        }`}
        onClick={() => handleLinkClick(text)} // Assuming you have a handleLinkClick function
      >
        <div className="flex flex-row items-center py-2 px-3 hover:bg-stone-50 gap-2 hover:outline outline-1 outline-stone-200 rounded-lg">
          {icon && <Icon icon={icon} className="text-xl" />}
          <p className="font-medium capitalize text-sm">{text}</p>
        </div>
      </Link>
    );
  };

  return (
    <div className="fixed top-0 w-full bg-sf-secondary z-[9999]">
      <div className="bg-[#3c54a4] h-[10px]"/>
    <div className=" flex flex-col md:flex-row    font-sans border-b border-stone-300 rounded-b-lg">

      <div className="flex flex-row w-full items-center justify-between py-2 px-3 text-black border-solid">

        {/* logo */}
        <Link to="/" className="flex flex-1">
          <img src={logo} className="h-16" alt="logo" />
        </Link>

        {/* center navlinks */}
        <div className="hidden md:flex md:flex-[5] gap-2 items-center justify-center ">
          <NavigationLink
            text={"Home"}
            icon={"ion:home"}
            path={"/"}
            activeLink={activeLink}
          />
          <NavigationLink
            text={"Flights"}
            icon={"clarity:plane-solid"}
            path={"Flights"}
            activeLink={activeLink}
          />
          <NavigationLink
            text={"Discover"}
            icon={"ion:compass"}
            path={"Discover"}
            activeLink={activeLink}
          />
          {/* <NavigationLink
            text={"Parking"}
            icon={"fluent:vehicle-car-parking-20-filled"}
            path={"Parking"}
            activeLink={activeLink}
          /> */}
          <NavigationLink
            text={"Transport"}
            icon={"solar:bus-bold"}
            path={"Transport"}
            activeLink={activeLink}
          />
        </div>

        {/* About us */}
        {!toggleNav && (
          <div className="max-sm:hidden flex-1 flex flex-row-reverse items-center text-sm hover:underline">
            <NavLink
              to="/About"
              onClick={() => handleLinkClick("About Us")}
              className={`${
                activeLink === "About Us"
                  ? "text-br-primary no-underline px-3"
                  : "text-tx-tertiary no-underline px-3"
              }`}
            >
              About Us
            </NavLink>
          </div>
        )}

        {/* menu for the mobile view */}
        <span
          onClick={handleNavToggle}
          className="flex md:hidden text-2xl text-gray-600"
        >
          {/* <HiMenuAlt2 /> */}
          <Icon icon="ion:menu" className="text-2xl text-br-primary" />
        </span>
      </div>

      {toggleNav && (
        <div className="bg-white-50 shadow-sm p-4">
          <ul className="flex flex-col items-start justify-center gap-4">
            <li>
              <Link
                className="text-gray-500 no-underline"
                to="/"
                onClick={() => setToggleNav(!toggleNav)}
              >
                Home
              </Link>
            </li>
            <li>
              <Link
                className="text-gray-500 no-underline"
                to="/flights"
                onClick={() => setToggleNav(!toggleNav)}
              >
                Flights
              </Link>
            </li>
            <li>
              <Link
                className="text-gray-500 no-underline"
                to="/discover"
                onClick={() => setToggleNav(!toggleNav)}
              >
                Discover
              </Link>
            </li>
            {/* <li>
              <a
                className="text-gray-500 no-underline"
                href="/parking"
                onClick={() => setToggleNav(!toggleNav)}
              >
                Parking
              </a>
            </li> */}
            <li>
              <Link
                className="text-gray-500 no-underline"
                to="/transport"
                onClick={() => setToggleNav(!toggleNav)}
              >
                Transport
              </Link>
            </li>
            <li>
              <Link
                className="text-gray-500 no-underline"
                to="/About"
                onClick={() => setToggleNav(!toggleNav)}
              >
                About Us
              </Link>
            </li>
          </ul>
        </div>
      )}
    </div>
    </div>
  );
};

export default Navbar;
