import React, { useState, useEffect } from "react";
import Tourism from "../TourismCard";
import { client } from "../../Sanity/sanity-client";
import imageUrlBuilder from "@sanity/image-url";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Link } from "react-router-dom";


const builder = imageUrlBuilder(client);
function urlFor(source) {
	return builder.image(source);
}

const InfiniteScroller = () => {
	const [cardDetails, SetCardDetails] = useState([]);

	useEffect(() => {
		client
			.fetch(
				`*[_type=="post" && "Explore Kalaburagi" in categories[]->title] | order(publishedAt desc){
				_id,
				title,
				slug,
				mainImage,
				body,
				blogBrief
				}`
			)
			.then((data) => SetCardDetails(data))
			.catch(console.error);
	}, []);
	const itemsPerPage = cardDetails.length; // Change this as needed

	// console.log("all card details", cardDetails);
	const [scrollPosition, setScrollPosition] = useState(0);

	// Handle scrolling
	const handleScroll = (e) => {
		const { scrollLeft, scrollWidth, clientWidth } = e.target;
		if (scrollLeft + clientWidth === scrollWidth) {
			// Reached the end of the scroll area, add more items
			setScrollPosition(scrollPosition + clientWidth);
		}
	};

	// Update scroll position
	useEffect(() => {
		document.getElementById("scroll-container").scrollLeft = scrollPosition;
	}, [scrollPosition]);

	// Function to scroll left
	const scrollLeft = () => {
		const container = document.getElementById("scroll-container");
		container.scrollBy({ left: -container.clientWidth, behavior: "smooth" });
	};

	// Function to scroll right
	const scrollRight = () => {
		const container = document.getElementById("scroll-container");
		container.scrollBy({ left: container.clientWidth, behavior: "smooth" });
	};

	return (
		<>

			<div className="hidden md:flex fade-left absolute top-0 bottom-0 left-0 w-20 bg-gradient-to-l items-center 
			justify-center bg-opacity-60 from-transparent opacity-100 to-white z-10">
				<ChevronLeft
					className="flex align-middle hover:cursor-pointer "
					onClick={scrollLeft}
				>
					Scroll Left
				</ChevronLeft>
			</div>

			<div className="hidden md:flex fade-right absolute top-0 bottom-0 right-0 w-20 bg-gradient-to-r 
			items-center justify-center from-transparent opacity-100 to-white z-10">
				<ChevronRight className="hover:cursor-pointer" onClick={scrollRight}>
					Scroll Right
				</ChevronRight>
			</div>

			<div
				className="w-full overflow-x-auto py-3 no-scrollbar relative"
				id="scroll-container"
				onScroll={handleScroll}
			>
				<div className="flex gap-10 pl-8 md:pl-16 w-full" style={{ width: `${itemsPerPage * 325}px` }}>
					{cardDetails.slice(0, 5).map((item) => {

						let description;
						if (item.blogBrief && item.blogBrief[0]) {
							description = item.blogBrief[0].children[0].text;
						} else if (item.body && item.body[0]) {
							description = item.body[0].children[0].text;
						}

						return (
							<Tourism
								id={item._id}
								source={urlFor(item.mainImage).url()}
								title={item.title}
								description={description} // Use the sliced description
								slug={item.slug.current}
							/>
						);
					})}

					<Link
						to="/blogs"
						className="flex items-center font-manrope w-fit gap-1.5 text-br-primary hover:cursor-pointer"
					>
						Show all blogs
					</Link>

				</div>

			</div>
		</>
	);
};

export default InfiniteScroller;
