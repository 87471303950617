import React, { useState } from "react";
import BusSection from "./BusSection";
import TrainSection from "./TrainSection";
import TaxiSection from "./TaxiSection";
import Intro from "../Intro";
import grad from "../../assets/Gradients/grad11.png";

const Transport = () => {
  const [transportType, setTransportType] = useState("bus");

  const changeTransport = (type) => {
    setTransportType(type);

    const button = document.getElementById(transportType + "Button");

    const buttons = document.querySelectorAll("button");
    buttons.forEach((btn) => {
      btn.classList.remove("bg-black", "text-white");
    });

    button.classList.add("bg-black", "text-white");
  };

  return (
    <>
      <div className="flex flex-col justify-center bg-[#F9F9F9] overflow-x-hidden">

        <Intro
          title="Transport"
          description="Discover convenient transportation options from the airport.
                Explore schedules and details for buses, trains, and taxis,
                making your travel from the airport a breeze."
          image={grad} />

        <div className="flex flex-col mx-auto my-24 w-11/12 md:w-7/12 items-center justify-center">
          <div className="w-fit bg-sf-secondary rounded-2xl p-1 shadow-sm flex flex-row gap-1.5 items-center border border-stone-200 justify-center shrink-0 relative">
            <button
              id="busButton"
              className={`${transportType === "bus"
                ? "bg-br-primary text-white"
                : "bg-white text-tx-secondary font-medium hover:bg-blue-50"
                } rounded-xl w-24 px-4 py-2 border border-stone-200 text-base flex flex-row gap-2.5 items-center justify-center `}
              onClick={() => changeTransport("bus")}
            >
              Bus
            </button>

            <button
              id="trainButton"
              className={`${transportType === "train"
                ? "bg-br-primary text-white"
                : "bg-white text-tx-secondary font-medium hover:bg-blue-50"
                } rounded-xl w-24 px-4 py-2 border border-stone-200 text-base flex flex-row gap-2.5 items-center justify-center `}
              onClick={() => changeTransport("train")}
            >
              Train
            </button>

            <button
              id="taxiButton"
              className={`${transportType === "taxi"
                ? "bg-br-primary text-white"
                : "bg-white text-tx-secondary font-medium hover:bg-blue-50"
                } rounded-xl w-24 px-4 py-2 border border-stone-200 text-base flex flex-row gap-2.5 items-center justify-center `}
              onClick={() => changeTransport("taxi")}
            >
              Taxi
            </button>
          </div>

          {transportType === "bus" ? (
            <BusSection />
          ) : transportType === "train" ? (
            <TrainSection />
          ) : transportType === "taxi" ? (
            <TaxiSection />
          ) : (
            <div></div>
          )}
        </div>
      </div>
    </>
  );
};
export default Transport;
