import React from "react";
import Intro from "../Intro";

import worldMap from "../../assets/Images/worldmap2.png"
import ieeecs from "../../assets/Images/IEEECS.png"
import grad from "../../assets/Gradients/grad01.png"

// user images
import aviral from "../../assets/People/Aviral.png"
import adith from "../../assets/People/Adith.png"
import sriram from "../../assets/People/Sriram.png"
import kartik from "../../assets/People/Kartik.png"
import ayush from "../../assets/People/Ayush.png"
import DevCard from "./devCard";

const peopleData = [
  {
    name: "Aviral Jain",
    role: "Project Manager and Backend Lead",
    imageUrl: aviral,
    linkedinUsername: "aviral-jain-20",
    githubUsername: "AviralJ58",
  },
  {
    name: "Sriram SS",
    role: "Frontend Lead",
    imageUrl: sriram,
    linkedinUsername: "sriram-s-s-349695210",
    githubUsername: "Sri0035",
  },
  {
    name: "Adith Narein T",
    role: "UI/UX Lead & Frontend Developer",
    imageUrl: adith,
    linkedinUsername: "adith-narein-t-005603207",
    githubUsername: "anxn",
  },
  {
    name: "Kartik Gupta",
    role: "Frontend Developer",
    imageUrl: kartik,
    linkedinUsername: "kartik-gupta-96394722b",
    githubUsername: "KartikGupta3",
  },
  {
    name: "Ayush Mishra",
    role: "Backend Developer and Designer",
    imageUrl: ayush,
    linkedinUsername: "ayush-mishra-b6b9741bb",
    githubUsername: "ayushh2k",
  },
];

const Devpage = () => {
  return (
    <>
      <Intro
        image={grad}
        title="Meet the Developers"
        description="Get acquainted with the brains behind the website - our airport
            technology enthusiasts who bring creativity and expertise to every
            line, turning ideas into seamless online experiences."
      />

      <div className="bg-sf-primary py-20 flex flex-col bg-cover items-center w-full justify-center gap-10"
        style={{
          backgroundImage: `url(${worldMap})`,

        }}>

        <h4 className="text-br-primary text-2xl font-semibold text-center">The Team</h4>

        <div className="flex flex-row flex-wrap items-stretch w-11/12 gap-10 justify-center flex-wrap">
          {peopleData.map((person, index) => (

            <DevCard
              key={index}
              name={person.name}
              role={person.role}
              imageUrl={person.imageUrl}
              linkedinUsername={person.linkedinUsername}
              githubUsername={person.githubUsername}
            />
          ))}
        </div>

        <img src={ieeecs} className="h-12 w-auto mt-8 md:mt-12" alt=""  />
      </div>
    </>
  );
};

export default Devpage;
