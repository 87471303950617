import React from 'react';
import { Icon } from "@iconify/react/dist/iconify.js";
import { Link } from 'react-router-dom';

const HotelCard = ({ hotel }) => {
    return (
        <div className="bg-white w-11/12 md:w-1/3 md:max-w-[50%] flex-grow mx-auto rounded-xl border-2 border-stone-200 flex flex-row overflow-clip">
            <div className="flex w-1/3 flex-col justify-center items-center bg-zinc-100">
                <Icon icon="fluent:building-20-filled" className="text-5xl text-br-primary" />
            </div>

            <div className="w-2/3 pl-6 p-4 justify-between h-full font-sans flex flex-col gap-6">
                <div className="flex flex-col gap-3">
                    <div className="flex flex-col gap-1">
                        <h3 className="text-xs font-semibold uppercase text-stone-400">Insight</h3>
                        <h3 className="text-lg font-semibold text-tx-primary">{hotel.title}</h3>
                    </div>
                    <div className="flex flex-col gap-2.5">
                        <div className="flex flex-row gap-2 items-center text-sm text-tx-secondary">
                            <Icon icon="ion:location" className="text-tx-secondary text-base" />
                            {hotel.address}
                        </div>
                        <div className="flex flex-row gap-2 items-center text-sm text-tx-secondary">
                            <Icon icon="ion:call" className="text-tx-secondary text-base" />
                            {hotel.contact}
                        </div>
                    </div>
                </div>

                <div className="flex flex-row w-full justify-end">
                    <Link
                        className="bg-stone-100 shadow-sm font-medium no-underline justify-center flex flex-row whitespace-nowrap gap-1.5
                         items-center justify-center px-2.5 py-1.5 text-br-primary text-xs uppercase rounded-md w-min hover:bg-blue-50"
                        to={hotel.link}
                        target="_blank"
                    >
                        Visit Website
                        <Icon icon="ion:globe" className="text-base" />
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default HotelCard;
