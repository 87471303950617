import React from "react";
import { Icon } from "@iconify/react/dist/iconify.js";


const DiscoverCard = (props) => {
    // Change this line
    const latitude = 17.329731;
    const longitude = 76.834296;

    const openGoogleMaps = (link) => {
        const url = `https://www.google.com/maps?q=${latitude},${longitude}`;
        window.open(link, "_blank");
    };

    return (
        <div className="flex flex-col md:flex-row bg-white shadow-sm border-2 w-11/12 md:w-7/12 rounded-md overflow-clip border-stone-200 hover:border-blue-900 md:flex">
            <img
                className="w-full md:w-1/3 h-auto object-cover border-r-2 border-stone-200"
                alt={props.title}
                src={props.source}
            />
            <div className="flex flex-col justify-start">
                <div className="flex items-center bg-sf-secondary py-3 px-4 border-stone-200 border-b-2 font-sans">
                    <h5 className="font-semibold text-br-primary text-lg">
                        {props.title}
                    </h5>

                    <div className="flex gap-2 justify-center items-center text-br-primary ml-auto">
                        <Icon icon="fluent:star-16-filled" className="text-xl" />
                        <div className="text-base font-medium ">4.3</div>
                    </div>
                </div>

                <p className="text-base text-tx-secondary p-4 leading-relaxed">
                    {props.description}
                </p>

                <div className="flex items-center p-4 font-sans">
                    <button
                        className="ml-auto shadow-sm text-sm tracking-wide text-br-primary bg-sf-secondary px-4 py-2 rounded-md shadow-sm font-medium hover:bg-gray-200"
                        onClick={() => openGoogleMaps(props.link)}
                    >
                        View in Maps
                    </button>
                </div>

            </div>
        </div>
    );
};

export default DiscoverCard;
