import React, { useState, useCallback, useEffect } from "react";
import axios from "axios";
import Star from "../../assets/Airlines/StarAirline.png";
import Alliance from "../../assets/Airlines/AllianceAirline.png";
import grad1 from "../../assets/Gradients/grad02.png";
import Intro from "../Intro";
import { Icon } from "@iconify/react/dist/iconify.js";

const Flights = () => {
  const [category, setCategory] = useState("departure");
  const [flightNumber, setFlightNumber] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [currentDate, setCurrentDate] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [etaCalculated, setEtaCalculated] = useState(false);
  const url = `https://mts-backend.azurewebsites.net/schedule/${category}?date=${currentDate}`;
  const handleSearch = useCallback(async () => {
    try {
      setSearchResults([]);
      const response = await axios(url);
      setSearchResults(response.data.schedules);
      // console.log(response.data.schedules);
    } catch (error) {
      console.log(error);
    }
  }, [url]);
  const getStatusColor = (status) => {
    switch (status.toLowerCase()) {
      case "on time":
      case "prepared":
      case "departed":
        return "bg-green-200 text-green-600 border border-green-600";

      case "delay":
      case "cancelled":
      case "diverted":
        return "bg-red-200 text-red-600 border border-red-600";

      case "rescheduled":
      case "scheduled":
        return "bg-yellow-200 text-yellow-600 border border-yellow-600";

      default:
        return "";
    }
  };

  const calculateETA = async (category, flightNumber, STA) => {
    var bodyFormData = new FormData();
    bodyFormData.append("category", category);
    bodyFormData.append("flight_number", flightNumber);
    try {
      const response = await axios({
        method: "post",
        url: "http://13.201.55.234/redirect",
        data: bodyFormData,
        headers: { "Content-Type": "application/form-data" },
      });
      // console.log(flightNumber);
      let updatedETA = response.data;
      // console.log("response", updatedETA);
      if (
        updatedETA === "'DataFrame' object has no attribute 'AIRCRAFT_TYPE'"
      ) {
        updatedETA = STA;
      } else {
        var str = updatedETA.split(" ");
        updatedETA = str[1];
      }

      setSearchResults((prevResults) => {
        const updatedResults = prevResults.map((flight) => {
          if (flight.FLIGHT_NO === flightNumber) {
            return {
              ...flight,
              ETA: updatedETA,
            };
          }
          return flight;
        });
        return updatedResults;
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    handleSearch();
  }, [handleSearch]);

  return (
    <div className="overflow-x-hidden bg-bg">
      {/* Intro */}
      <Intro
        title="Flight Schedule"
        description="Explore the scheduled flights for your desired destinations. Find departure and arrival times, airlines, and durations for hassle-free travel planning."
        image={grad1}
      />

      <section className="flex flex-col w-10/12 py-32 mx-auto gap-12 font-sans ">
        {/* Flight Search */}
        <div className="flex flex-col items-center justify-between self-stretch md:flex-row">
          {/* date & type */}
          <div className="flex flex-col gap-4 items-center justify-center font-sans shrink-0 relative lg:flex-row justify-start">
            <div className="bg-sf-secondary rounded-2xl p-1 shadow-sm flex flex-row gap-1.5 items-center  border border-stone-200  justify-center shrink-0  relative md:justify-start">
              <button
                className={`${
                  category === "departure"
                    ? "bg-br-primary text-white"
                    : "bg-white text-tx-secondary hover:bg-blue-50"
                } rounded-xl px-4 py-2.5 border border-stone-200 text-lg flex flex-row gap-2.5 items-center justify-center `}
                onClick={() => setCategory("departure")}
              >
                <Icon
                  icon="vaadin:flight-takeoff"
                  className="relative text-lg"
                />
                <p className="text-sm font-medium">Departure</p>
              </button>

              <button
                className={`${
                  category === "arrival"
                    ? "bg-br-primary text-white"
                    : "bg-white text-tx-secondary hover:bg-blue-50"
                } rounded-xl px-4 py-2.5 text-lg flex flex-row border border-stone-200 gap-2.5 items-center justify-center `}
                onClick={() => setCategory("arrival")}
              >
                <Icon
                  icon="vaadin:flight-landing"
                  className="relative text-lg"
                />
                <p className="text-sm font-medium">Arrival</p>
              </button>
            </div>

            <div className="bg-sf-secondary rounded-2xl p-1 shadow-sm flex flex-row gap-1.5 items-center  border border-stone-200  justify-center shrink-0  relative md:justify-start">
              <input
                type="date"
                className="rounded-xl bg-sf-white text-tx-primary px-3 py-2 text-sm font-semibold border border-stone-200 flex flex-row gap-0 items-center justify-center"
                value={currentDate}
                onChange={(e) => setCurrentDate(e.target.value)}
              />
            </div>
          </div>

          {/* search bar */}
          <div className="flex flex-row overflow-clip rounded-2xl text-base border border-stone-200  ">
            <div className="bg-sf-secondary p-1 shadow-sm flex flex-row  items-center justify-center shrink-0  relative md:justify-start">
              <input
                className="w-[225px] rounded-xl bg-sf-white text-tx-primary px-3 py-2.5 text-sm font-medium border border-stone-200 flex flex-row gap-0 items-center justify-center"
                placeholder="Flight Number"
                value={flightNumber}
                onChange={(e) => setFlightNumber(e.target.value)}
              />
            </div>
            <button
              className="bg-br-primary text-white font-medium px-4 py-3 flex flex-row gap-2.5 items-center justify-center"
              onClick={handleSearch}
            >
              Search
            </button>
          </div>
        </div>

        {/* Table */}
        <div className="flex flex-col gap-4 mb-24 mx-auto w-full ">
          <div className="overflow-x-auto border w-full border-stone-200 shadow-sm rounded-lg lg:overflow-clip ">
            <table className="w-full bg-white">
              <tr className="text-stone-50 text-xs bg-black text-center uppercase">
                <th className="py-2.5 px-5 text-left font-semibold ">
                  Airline
                </th>
                <th className="py-2.5 px-4 font-semibold ">Flight No</th>
                <th className="py-2.5 px-4 font-semibold ">Origin</th>
                <th className="py-2.5 px-4 font-semibold ">Destination</th>
                <th className="py-2.5 px-2 font-semibold ">STD</th>
                <th className="py-2.5 px-2 font-semibold ">STA</th>
                <th className="py-2.5 px-2 font-semibold ">ETA</th>
                <th className="py-2.5 px-2 font-semibold ">Action</th>
                <th className="py-2.5 px-2 font-semibold ">Status</th>
                <th className="py-2.5 px-2 font-semibold ">
                  {category === "arrival" ? "Belt No." : "Gate No."}
                </th>
              </tr>
              {searchResults.map((flight, index) => (
                <tr
                  key={index}
                  className="bg-sf-white border-t font-medium text-tx-secondary border-gray-300 text-center"
                >
                  {flightNumber === flight.FLIGHT_NO ? (
                    <>
                      <td className="py-2.5 px-2">
                        {flight.FLIGHT_NO.substring(0, 2) === "9I" && (
                          <img src={Alliance} alt="" />
                        )}
                        {flight.FLIGHT_NO.substring(0, 2) === "S5" && (
                          <img src={Star} alt="" />
                        )}
                      </td>
                      <td className="py-2.5 px-4 text-br-primary">
                        {flight.FLIGHT_NO}
                      </td>
                      <td className="py-2.5 px-4">{flight.ORIGIN}</td>
                      <td className="py-2.5 px-4">{flight.DESTINATION}</td>
                      <td className="py-2.5 px-2">{flight.STD}</td>
                      <td className="py-2.5 px-2">{flight.STA}</td>
                      <td className="py-2.5 px-2">
                        {etaCalculated ? flight.ETA || 0 : 0}
                      </td>
                      <td className="py-2.5 px-2">
                        <button
                          onClick={() => {
                            calculateETA(
                              category,
                              flight.FLIGHT_NO,
                              flight.STA
                            );
                            setEtaCalculated(true);
                          }}
                          className="bg-br-primary text-xs text-white py-1 px-2 rounded-lg font-semibold uppercase "
                        >
                          Check ETA
                        </button>
                      </td>
                      <td
                        className={`flex justify-center rounded-lg p-1 m-2 ${getStatusColor(
                          flight.STATUS
                        )}`}
                      >
                        {flight.STATUS}
                      </td>

                      <td className=" py-2.5 px-2">{flight.BELT_NUMBER}</td>
                    </>
                  ) : (
                    <>
                      {flightNumber.trim() === "" ? (
                        <>
                          <td className=" py-2.5 px-2">
                            {flight.FLIGHT_NO.substring(0, 2) === "9I" && (
                              <img src={Alliance} alt="" />
                            )}
                            {flight.FLIGHT_NO.substring(0, 2) === "S5" && (
                              <img src={Star} alt="" />
                            )}
                          </td>
                          <td className="py-2.5 px-4 text-br-primary">
                            {flight.FLIGHT_NO}
                          </td>
                          <td className="py-2.5 px-4">{flight.ORIGIN}</td>
                          <td className="py-2.5 px-4">{flight.DESTINATION}</td>
                          <td className="py-2.5 px-2">{flight.STD}</td>
                          <td className="py-2.5 px-2">{flight.STA}</td>
                          <td className="py-2.5 px-2">
                            {etaCalculated ? flight.ETA || 0 : 0}
                          </td>
                          <td className="py-2.5 px-2">
                            <button
                              onClick={() => {
                                calculateETA(
                                  category,
                                  flight.FLIGHT_NO,
                                  flight.STA
                                );
                                setEtaCalculated(true);
                              }}
                              className="bg-br-primary text-xs text-white py-1.5 px-3 rounded-lg hover:bg-blue-800 font-semibold uppercase "
                            >
                              Check ETA
                            </button>
                          </td>
                          <td className="py-2.5 px-2">
                            <p
                              className={`flex justify-center items-center text-sm uppercase font-semibold rounded-lg  p-1  ${getStatusColor(
                                flight.STATUS
                              )}`}
                            >
                              {flight.STATUS}
                            </p>
                          </td>

                          <td className=" py-2.5 px-2">
                            {category === "arrival"
                              ? flight.BELT_NUMBER
                              : flight.GATE_NUMBER}
                          </td>
                        </>
                      ) : (
                        <td></td>
                      )}
                    </>
                  )}
                </tr>
              ))}
            </table>
          </div>

          <div className="flex flex-col justify-between w-full gap-2 leading-relaxed  text-gray-600 text-sm">
            <p className="text-left">
              Click the <b>"Check ETA"</b> button to calculate the Estimated
              Time of Arrival/Departure for the selected flight.
            </p>
            <p className="text-left">
              <span className="text-red-600"> {"* "} </span>
              The ETA will be updated based on the latest information available.
            </p>
          </div>
        </div>
      </section>
    </div>
    // </div>
  );
};
export default Flights;
