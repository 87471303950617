import React from "react";
import AboutUs from "../../assets/Images/AboutUs.png"
import Plane from "../../assets/Images/Plane.png";
import AboutNav from "./nav";
import { Icon } from "@iconify/react/dist/iconify.js";

const About = () => {
  return (
    <>
      {/* intro */}
      <div className="flex flex-col items-center justify-center relative gap-10">
        <img src={AboutUs} alt="" className="w-screen h-72 md:h-80 lg:h-full object-cover" />
        <h2 className="relative md:absolute md:top-[25%] m-auto text-br-primary text-center leading-snug font-elsie text-2xl md:text-5xl">
          Kalaburagi Airport <br /> Karnataka
        </h2>
      </div>

      {/* About Kalaburagi  */}
      <div className="flex flex-col gap-6 mx-auto w-11/12 md:w-8/12 my-10 md:my-20">
        <div className="flex flex-col md:flex-row border border-br-primary rounded-sm overflow-hidden">
          <div className="flex flex-col flex-4 gap-5 p-6 text-left leading-relaxed">
            <h1 className="text-left self-stretch font-sans font-medium text-br-primary leading-relaxed text-base lg:text-xl">
              Kalaburagi Airport - Gateway to North Karnataka
            </h1>
            <p>
              Kalaburagi Airport (GBI), is located 12 kilometers east of
              Kalaburagi city in Karnataka, India, serves as a vital aerial
              link for the region. Operational since November 22nd, 2019, the
              airport is managed by the Airports Authority of India.
            </p>
          </div>
          <img
            alt="airport runway"
            className="w-full md:w-[30%] h-full border-l border-br-primary"
            src={Plane}
          />
        </div>

        {/* Kalaburagi Sectors */}
        <div className="flex flex-col mt-10 border border-br-primary rounded-sm overflow-hidden ">
          <div className="flex flex-col gap-3  p-5 border-b border-br-primary bg-sf-secondary">
            <h2 className="w-full text-br-primary font-semibold font-medium">A Flourishing City: Kalaburagi</h2>
            <p className="w-full text-tx-primary leading-relaxed">
              Nestled amidst the Deccan Plateau, Kalaburagi, formerly known as
              Gulbarga, is a vibrant city pulsating with life in North
              Karnataka. The city's economy thrives on several key sectors:
            </p>
          </div>

          <div className="flex flex-col p-5 gap-3 text-sm md:text-base text-tx-primary ">
            <div className="flex flex-row gap-4">
              <Icon icon="ion:school" className="text-br-primary text-lg my-1" />
              <p className="w-full leading-relaxed">
                <span className="font-semibold text-br-primary">Education Sector: </span> Kalaburagi boasts two esteemed universities -
                the Central University of Karnataka and Gulbarga University -
                along with over 241 colleges. Renowned for their quality
                education and research, these institutions offer a diverse range
                of courses in arts, humanities, management, sciences, and
                engineering.
              </p>
            </div>
            <div className="flex flex-row gap-4">
              <Icon icon="ion:rose" className="text-br-primary text-lg my-1" />
              <p className="w-full leading-relaxed">
                <span className="font-semibold text-br-primary">Religious Sector: </span> Kalaburagi's rich tapestry is woven with
                history and cultural heritage. The city has been a part of
                numerous empires over the centuries, leaving behind a treasure
                trove of religious sites. Some of the most prominent include:
                Sharana Basaveshwar Temple Jamia Masjid Chandrala Parameshwari
                Temple - Sannati Dargah of Khwaja Banda Nawaz Uttaradi Matha
              </p>
            </div>
            <div className="flex flex-row gap-4">
              <Icon icon="ion:sparkles" className="text-br-primary text-lg my-1" />
              <p className="w-full leading-relaxed">
                <span className="font-semibold text-br-primary">Tourism Sector: </span> Kalaburagi offers a captivating blend of
                historical landmarks and recreational escapes. Tourists can
                explore the city's glorious past at: Mahboob Gulshan (Public
                Gardens) Shri. Sharana Basaveshwar Tank Bahamani Fort The city's
                major religious centers, Shri. Sharana Basaveshwara Temple and
                The Khwaja Banda Nawaz Dargah, attract lakhs of pilgrims
                annually during jatra and Urs celebrations. The magnificent
                Gulbarga Fort, built by the Bahamani dynasty, is another major
                tourist draw.
              </p>
            </div>
            <div className="flex flex-row gap-4">
              <Icon icon="ion:business" className="text-br-primary text-lg my-1" />
              <p className="w-full leading-relaxed">
                <span className="font-semibold text-br-primary">Industrial Sector: </span> Kalaburagi is rightfully called the "tur
                bowl" of Karnataka, housing around 500 dal mills in the
                district. The region's rich limestone deposits have also
                attracted large cement manufacturing units.
              </p>
            </div>
            <div className="flex flex-row gap-4">
              <Icon icon="fluent:food-grains-20-filled" className="text-br-primary text-lg my-1" />
              <p className="w-full leading-relaxed">
                <span className="font-semibold text-br-primary">Agriculture Sector: </span> Agriculture forms the backbone of
                Kalaburagi's economy, with a majority of the population engaged
                in this sector. The city is renowned as the state's "tur bowl,"
                contributing a significant 40% of Karnataka's tur production.
              </p>
            </div>
          </div>

        </div>

      </div>
      
      <AboutNav />
    </>
  );
};

export default About;
