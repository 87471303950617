import { createClient, groq } from "@sanity/client";

const client = createClient({
  projectId: "4dbz6wla",
  dataset: "production",
  useCdn: false,
  apiVersion: "2023-11-03",
});

const getBlogDetails = async () => {
  return client.fetch(`*[_type == 'post'] {
    _id,
    title,
    slug,
    author-> {
      _id,
      name
    },
    mainImage,
    categories[]-> {
      _id,
      title
    },
    publishedAt,
    body
  }`);
};

const getBlogCardDetails = async () => {
  return client.fetch(`*[_type == "post"]{
    _id,
    title,
    mainImage,
    slug
  }`);
};

export { client, getBlogDetails, getBlogCardDetails };
