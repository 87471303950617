import React from "react";
import Bgm from "../../assets/blog.svg";
import blog1 from "../../assets/blog1.svg";
import blog2 from "../../assets/blog2.svg";
import blog3 from "../../assets/blog3.svg";
import Tourism from "../TourismCard";
const BgmPic = () => {
  return (
    <div className="relative">
      <img src={Bgm} alt="" className="w-screen h-80 md:h-auto lg:h-auto" />
      <div
        className="absolute space-y-2 md:space-y-4 lg:space-y-6 top-1/3 left-1/4 transform -translate-y-1/2 text-black"
        style={{ marginLeft: "-20%", marginTop: "90px" }}
      >
        <h2 className="text-xl text-blue-800 sm:text-2xl md:text-4xl lg:text-5xl font-vietnam mb-1 md:mb-4">
          Tourism Blogs
        </h2>
        <p className="text-xs md:text-xs lg:text-base font-vietnam font-medium tracking-tighter md:tracking-widest mb-2">
          Stay up-to-date with our latest blogs offering travel tips and
          insights. Don't miss out on our diverse range of upcoming events, from
          workshops to cultural experiences.
          <br /> Discover more and enhance your airport journey with valuable
          content and memorable activities.
        </p>
      </div>
    </div>
  );
};

const BlogCardData = [
  {
    imageurl: blog1,
    heading: "NEW WEBSITE LAUNCHED FOR KALABURAGI AIRPO..",
    data: "Make your travel experience better by taking advantage of our convenient parking facilities.",
  },
  {
    imageurl: blog2,
    heading: "AIRPORT WELLNESS WEEK: RELAX & RECHAR...",
    data: "Make your travel experience better by taking advantage of our convenient parking facilities.",
  },
  {
    imageurl: blog3,
    heading: "UPCOMING EVENT AT KALABURAGI...",
    data: "Make your travel experience better by taking advantage of our convenient parking facilities.",
  },
];

// const AllCards = ({ imageurl, heading, data }) => {
//   return (
//     <div className="max-w-xs mt-4 md:mt-6 border-2 border-black overflow-hidden shadow-[5px_5px_black] hover:shadow">
//       <img className="w-full" src={imageurl} alt="" />
//       <div className="px-6 py-4">
//         <div className="font-bold text-xl mb-2">{heading}</div>
//         <p className="text-gray-700 text-base">{data}</p>
//       </div>
//       <div className=" z-20 px-6 pt-4 pb-2">
//         <div className=" text-blue-500 font-vietnam text-right">
//           find out more
//         </div>
//       </div>
//     </div>
//   );
// };

const TourismBlogs = () => {
  return (
    <>
      <BgmPic />
      <h2 className="text-2xl font-bold font-vietnam text-blue-800 my-4 text-center md:text-3xl my-8">
        Latest Blogs
      </h2>
      <div className="flex-row gap-8 p-4 md:flex-row p-8 lg:z-20 flex flex-col justify-center items-center">
        {BlogCardData.map((text, index) => (
          <Tourism
            key={index}
            source={text.imageurl}
            title={text.heading}
            desccription={text.data}
          />
        ))}
      </div>
      <div className="flex-row gap-8 p-4 md:flex-row p-8 lg:z-20 flex flex-col justify-center items-center">
        {BlogCardData.map((text, index) => (
          <Tourism
            key={index}
            source={text.imageurl}
            title={text.heading}
            desccription={text.data}
          />
        ))}
      </div>
      <div className="flex-row gap-8 p-4 md:flex-row p-8 lg:z-20 flex flex-col justify-center items-center">
        {BlogCardData.map((text, index) => (
          <Tourism
            key={index}
            source={text.imageurl}
            title={text.heading}
            desccription={text.data}
          />
        ))}
      </div>
    </>
  );
};

export default TourismBlogs;
