import React from "react";
import AllianceAir from "../../../assets/Airlines/AllianceAir.png";
import StarAir from "../../../assets/Airlines/StarAir.png";
import { Link } from "react-router-dom";
import { Icon } from "@iconify/react/dist/iconify.js";

const Airlines = [
  {
    source: AllianceAir,
    title: "Alliance Air (9I)",
    address: "address",
    contact: "08472-295634",
    link: "https://www.allianceair.in/",
  },
  {
    source: StarAir,
    title: "Star Air (S5)",
    address: "address",
    contact: "08472-295309",
    link: "https://starair.in/",
  },
];

const AirlineCard = ({ airline }) => {
  return (
    <div className="bg-white w-full md:w-1/3 flex-1 md:max-w-[50%] font-sans flex-grow mx-auto rounded-xl border-2 border-stone-200 flex flex-row overflow-clip">
      <div className="flex w-1/3 flex-col justify-center items-center bg-zinc-100">
        <img src={airline.source} alt="air logo" className="object-fit" />
      </div>

      <div className="w-2/3 pl-6 p-4 justify-between h-full font-sans flex flex-col gap-6">
        <div className="flex flex-col gap-3">
          <div className="flex flex-col gap-1">
            <h3 className="text-xs font-semibold uppercase text-stone-400">Insight</h3>
            <h3 className="text-lg font-semibold text-tx-primary">{airline.title}</h3>
          </div>
          <div className="flex flex-col gap-2.5">
            <div className="flex flex-row gap-2 items-center text-sm text-tx-secondary">
              <Icon icon="ion:location" className="text-tx-secondary text-base" />
              {airline.address}
            </div>
            <div className="flex flex-row gap-2 items-center text-sm text-tx-secondary">
              <Icon icon="ion:call" className="text-tx-secondary text-base" />
              {airline.contact}
            </div>
          </div>
        </div>

        <div className="flex flex-row w-full justify-end">
          <Link
            className="bg-stone-100 shadow-sm font-semibold no-underline items-center justify-center flex flex-row whitespace-nowrap gap-1.5
              items-center justify-center px-2.5 py-1.5 text-br-primary text-xs uppercase rounded-md w-min hover:bg-blue-50"
            to={airline.link}
            target="_blank"
          >
            Visit Website
            <Icon icon={"ion:globe"} className="text-br-primary text-base" />
          </Link>
        </div>
      </div>
    </div>
  );
};

const AirlinePage = () => {
  return (
    <div className="flex flex-col font-sans items-center justify-center gap-8">

      <div className="flex flex-col gap-2 w-fit items-center text-center">
        <div className="text-lg text-tx-primary font-semibold md:text-xl">
          Airline Operators at Kalaburagi Airport
        </div>
        <div className="h-0.5 w-10/12 bg-br-primary rouded-full"></div>
      </div>

      <div className="flex w-full justify-center flex-col md:flex-row flex-wrap gap-6 md:gap-10">
        {Airlines.map((airline, index) => (
          <AirlineCard key={index} airline={airline} />
        ))}
      </div>
    </div>
  );
};

export default AirlinePage;
