import React from "react";
import { Link } from "react-router-dom";
import { Icon } from "@iconify/react/dist/iconify.js";

const DevCard = (props) => {
    return (
        <div className="flex flex-col h-full items-center justify-between w-52 border-2 border-stone-200 bg-sf-secondary shadow-lg p-1 gap-4 items-center rounded-2xl justify-center">
            <img src={props.imageUrl} alt="user Image" className="aspect-square w-full border border-stone-200 rounded-xl" />
            <div className="flex flex-col items-center justify-center w-full gap-1 px-5 ">
                <h3 className="font-sans font-medium text-lg text-br-primary">{props.name}</h3>
                <p className="font-sans text-tx-secondary text-center leading-relaxed text-sm">{props.role}</p>
            </div>
            <div className="flex flex-row items-center justify-center w-full gap-1">
                <a
                    href={`https://www.linkedin.com/in/${props.linkedinUsername}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="w-full flex items-center justify-center p-2.5 bg-stone-200 hover:bg-indigo-100 rounded-lg"
                >
                    <Icon icon="mdi:linkedin" className="text-br-primary text-2xl" />
                </a>
                <a
                    href={props.name === "Adith Narein T" ? `https://bento.me/${props.githubUsername}` : `https://github.com/${props.githubUsername}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="w-full flex items-center justify-center p-2.5 bg-stone-200 hover:bg-indigo-100 rounded-lg"
                >
                    <Icon icon={props.name === "Adith Narein T" ? "mdi:behance" : "mdi:github"} className="text-br-primary text-2xl" />
                </a>
            </div>
        </div>
    );
};

export default DevCard;