import React, { useState, useEffect } from "react";
import Tourism from "../TourismCard";
import { client } from "../../Sanity/sanity-client";
import imageUrlBuilder from "@sanity/image-url";

const itemWidth = 0; // Change this as needed

const builder = imageUrlBuilder(client);
function urlFor(source) {
  return builder.image(source);
}

const InfiniteScrollerExplore = () => {
  const [cardDetails, SetCardDetails] = useState([]);
  useEffect(() => {
    client
      .fetch(
        `*[_type=="post" && "Airport Blogs" in categories[]->title]{
          _id,
          title,
          slug,
          mainImage,
          body
          }`
      )
      .then((data) => SetCardDetails(data))
      .catch(console.error);
  }, []);

  const loopCardDetails = []
  for (let i=0; i <3 ; i++){
    loopCardDetails.push(...cardDetails);
  }

  const itemsPerPage = loopCardDetails.length; // Change this as needed

  // console.log("all card details", cardDetails);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [currentIndex, setCurrentIndex] = useState(0);

  

  // Handle scrolling
  const handleScroll = (e) => {
    const { scrollLeft, scrollWidth, clientWidth } = e.target;
    const maxScroll = scrollWidth - clientWidth;
  
    if (scrollLeft + clientWidth >= scrollWidth) {
      // Reached the end of the scroll area, reset to the beginning
      setScrollPosition(0);
      setCurrentIndex(0);
    } else {
      // Scroll to the next item or loop back to the beginning if at the end
      const nextIndex = Math.floor((scrollLeft / maxScroll) * itemsPerPage);
      setScrollPosition(nextIndex * (maxScroll / itemsPerPage));
      setCurrentIndex(nextIndex);
    }
  };

  // Update scroll position
  useEffect(() => {
    document.getElementById("scroll-container").scrollLeft = scrollPosition;
  }, [scrollPosition]);

  return (
    <>
      <div className="fade-left absolute top-0 bottom-0 left-0 w-12 bg-gradient-to-l from-transparent to-white z-10"></div>
      <div className="fade-right absolute top-0 bottom-0 right-0 w-12 bg-gradient-to-r from-transparent to-white z-10"></div>
      <div
        className="w-full overflow-x-auto  relative"
        id="scroll-container"
        onScroll={handleScroll}
        style={{ overflowX: "hidden" }}
      >
        <div
          className="flex space-x-5"
          style={{ width: `${itemsPerPage * itemWidth}px` }}
        >
          
          {loopCardDetails.map((item, index) => {
            if(index == loopCardDetails.length - 1) {
              
            }
            // Extract the first 20 words from the body text
            const bodyText = cardDetails[index].body[0].children[0].text;
            const words = bodyText.split(" ");
            const description = words.slice(0, 20).join(" "); // Select the first 20 words
            
            return (
              <div key={item._id}>
                <Tourism
                  source={urlFor(cardDetails[index].mainImage).url()}
                  title={cardDetails[index].title}
                  description={description} // Use the sliced description
                  slug={cardDetails[index].slug.current}
                />
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default InfiniteScrollerExplore;
