import React from "react";
import TaxiCard from "./TaxiCard";
import { Icon } from "@iconify/react/dist/iconify.js";

const taxiServices = [
  {
    title: "Samanvaya Multipurpose Cooperative Society",
    number: "Not available",
    description: "A/C and Non A/C Taxi service Landmark - NEAR ARRIVAL EXIT"
  },
  {
    title: "SHREYA TOURS & TRAVELS",
    number: "9844514144",
    description: "Shreya Tours & Travels is one of the leading taxi service providers in the Kalaburagi region. The taxi service company has a team of professionals with years of experience in the taxi service."
  },
  {
    title: "ABHISHEK TOURS AND TRAVELS",
    number: "9972292888",
    description: "Abhishek Tours and Travels has been serving the professional taxi service in the greater Gulbarga region. They have made a reputation in the travel industry through their service and professional approach by satisfying every customer they deal with."
  },
  {
    title: "SRI SIDDHIVINAYAKA TOURS & TRAVELS",
    number: "8884980599",
    description: "Sri Siddhivinayaka Tours & Travels is a well-renowned cab service provider company in the Gulbarga region. Their cab drivers are excellently trained and well-behaved to provide their customers with impeccable service."
  }
];

const TaxiSection = () => {
  return (
    <div className="flex flex-col items-center font-sans py-12 gap-12">

      {/* title */}
      <h1 className="text-xl font-semibold text-br-primary pb-4 text-center w-full border-b-2 border-br-primary">
        Taxi & Cabs in Airport
      </h1>

      {/* Steps for the users */}
      <div className="flex flex-col gap-0 items-start justify-center ">

        <div className="flex flex-row items-center justify-center gap-4 md:gap-6">
          <div className="p-3 rounded-2xl bg-br-primary">
            <Icon icon="fluent:luggage-24-filled" className="text-lg md:text-2xl text-white" />
          </div>
          <div className="font-medium text-tx-primary text-base md:text-lg w-full">Collect your luggages</div>
        </div>

        <hr className="h-6 md:h-8 w-0.5 mx-5 md:mx-6 bg-br-primary" />

        <div className="flex flex-row items-center justify-center gap-4 md:gap-6">
          <div className="p-3 rounded-2xl bg-br-primary">
            <Icon icon="fluent:door-20-filled" className="text-lg md:text-2xl text-white" />
          </div>
          <div className="font-medium text-tx-primary text-base md:text-lg w-full">Leave through the gate</div>
        </div>

        <hr className="h-6 md:h-8 w-0.5 mx-5 md:mx-6 bg-br-primary" />

        <div className="flex flex-row items-center justify-center gap-4 md:gap-6">
          <div className="p-3 rounded-2xl bg-br-primary">
            <Icon icon="ph:taxi-fill" className="text-lg md:text-2xl text-white" />
          </div>
          <div className="font-medium text-tx-primary text-base md:text-lg w-full">Pick your favourite taxi and leave home!</div>
        </div>

      </div>

      {/* Taxi List */}
      <div className="flex flex-col gap-6 md:gap-8 items-center justify-center">
        <h2 className="text-xl font-semibold text-br-primary pb-4 text-center w-full border-b-2 border-br-primary">Taxi Providers</h2>
        {/* <div className="flex flex-col gap-5">
          {
            taxiServices.map((service, index) => (
              <TaxiCard
                key={index}
                Title={service.title}
                Number={service.number}
                Description={service.description}
              />
            ))
          }
        </div> */}
        <div className="text-xl font-semibold">Coming Soon!</div>
      </div>
    </div>
  );
};

export default TaxiSection;
