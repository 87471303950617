import React, { useState } from "react";
import LeadershipPage from "./pages/LeadershipPage";
import MapPage from "./pages/MapPage";
import AirlinePage from "./pages/AirlinePage";
import FlightSchoolPage from "./pages/FlightSchoolPage";
import RegulatoryPage from "./pages/RegulatoryPage";
import ContactPage from "./pages/ContactPage";
import { Icon } from "@iconify/react/dist/iconify.js";

const AboutNav = () => {
  const [clicked, setClicked] = useState(0);

  const handleItemClick = (index) => {
    setClicked(index);
  };

  const navItems = [
    { icon: "fluent:people-team-24-filled", text: "Leadership" },
    { icon: "ion:map", text: "Map" },
    { icon: "ion:airplane", text: "Airlines" },
    { icon: "ion:school", text: "Flight School" },
    { icon: "mage:contact-book-fill", text: "Contact" },
    { icon: "ion:lock-open", text: "Regulatory" },
  ];

  return (
    <div className="flex flex-col justify-center w-11/12 md:w-8/12 mx-auto mb-20">
      <div className="bg-br-primary w-full md:w-fit mx-auto overflow-x-scroll md:overflow-hidden flex flex-row justify-start 
      rounded-lg md:rounded-full border-2 border-stone-200 md:justify-center font-sans items-center p-3 md:p-1 gap-4 md:gap-1">
        {navItems.map((item, index) => (
          <div
            key={index}
            className={`${
              clicked === index ? "bg-sf-secondary text-br-primary border border-stone-200 font-medium" : "text-white border border-indigo-900 hover:bg-sky-800"
            } text-center justify-center text-lg flex flex-row 
              items-center justify-center cursor-pointer p-2 md:px-4 gap-2 rounded-lg md:rounded-full`}
            onClick={() => handleItemClick(index)}
          >
            <Icon icon={item.icon} className="text-2xl" />
            <p className={`${clicked === index ? "block" : "hidden"} md:block text-nowrap text-base w-full`}>{item.text}</p>
          </div>
        ))}
      </div>
      <div className="flex flex-col py-8 md:py-12">
        {clicked === 0 && <LeadershipPage />}
        {clicked === 1 && <MapPage />}
        {clicked === 2 && <AirlinePage />}
        {clicked === 3 && <FlightSchoolPage />}
        {clicked === 4 && <ContactPage />}
        {clicked === 5 && <RegulatoryPage />}
      </div>
    </div>
  );
};

export default AboutNav;
