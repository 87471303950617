import { Icon } from "@iconify/react/dist/iconify.js";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import logo from '../assets/Images/aai_logo.png'

const NavLinkItem = ({ to, children }) => (

	<Link className="text-tx-secondary hover:text-br-primary hover:underline" to={to}>
		{children}
	</Link>
)

const SocialsIcon = ({ to, name }) => (
	<button onClick={() => window.location.href = `${to}`}>
		<Icon className="text-2xl text-tx-primary hover:text-br-primary hover:underline" icon={name} />
	</button>
)

const Footer = () => {
	const [mail, setmail] = useState("");
	const handleButtonClick = () => {
		window.location.href = `mailto:${"kalaburagisupport@gmail.com"}`;
	};
	return (
		<footer>
			<div className="flex flex-col gap-6 p-6 md:p-10 bg-sf-secondary text-tx-primary font-sans rounded-t-3xl border-t-2 border-gray-300">

				{/* top section */}
				<div className="w-full flex flex-row flex-wrap md:flex-row justify-between md:justify-center md:grid md:grid-cols-2 lg:grid-cols-4 gap-10">

					{/* logo & caption */}
					<div className="flex flex-col gap-3 w-full md:w-fit md:items-center items-start">
						<div className="flex flex-col gap-0 items-start">
							<img src={logo}
								className="h-28 w-auto object-fit" alt="Kalaburagi Logo"
							/>
							<p className="text-[11px] text-[#3a52a3] px-1.5"> Kalaburagi, Karnataka </p>
						</div>
						<p className="text-br-primary text-lg font-elsie leading-normal sm:text-left">
							Not just an Airport. <br />
							An Experience!
						</p>
					</div>

					{/* links 1 */}
					<div className="flex flex-col gap-3 w-fit md:px-3">
						<p className="font-sans w-fit font-medium text-br-primary">Quick Links</p>
						<div className="flex flex-col gap-2 text-sm">
							<NavLinkItem to="/about">About Us</NavLinkItem>
							<NavLinkItem to="/blogs">Blogs</NavLinkItem>
							<NavLinkItem to="/tourism">Tourism</NavLinkItem>
							<NavLinkItem to="/meetdevelopers">Developers Page</NavLinkItem>
						</div>
					</div>

					{/* links 2 */}
					<div className="flex flex-col gap-3 w-fit md:px-3">
						<p className="font-sans w-fit font-medium text-br-primary">Airport</p>
						<div className="flex flex-col gap-2 text-sm">
							<NavLinkItem to="/parking">Parking</NavLinkItem>
							<NavLinkItem to="/flights">Flights</NavLinkItem>
							<NavLinkItem to="/transport">Transport</NavLinkItem>
							<NavLinkItem to="/about">About Us</NavLinkItem> {/* Assuming this is intentional */}
						</div>
					</div>

					{/* mail Contianer */}
					<div className="flex flex-col gap-2 md:gap-3">
						<p className="font-sans font-medium text-br-primary">Hey 👋, Lets get in touch!</p>
						<form className="flex flex-row border rounded-lg overflow-hidden border-gray-300 items-center">
							<input
								type="text"
								placeholder="Enter your mail ID"
								className="h-full w-full px-3 py-3 focus:outline-none rounded-l text-sm"
								value={mail}
								onChange={(e) => setmail(e.target.value)}
							/>
							<button
								className="h-full p-3 bg-br-primary"
								onClick={handleButtonClick}
							>
								<Icon icon="ion:mail-unread" className="text-xl text-white" />
							</button>
						</form>
						<p className="text-tx-tertiary text-[13px]">
							<span className="text-red-800">{"* "}</span>
							We will be sending you updates about the
							airport to you.
						</p>
					</div>

				</div>

				<hr className="border-b border-gray-300 w-full mx-auto" />

				{/* bottom section */}
				<div className="w-full flex flex-col md:flex-row p-2 gap-5 items-center justify-between">

					{/* Social Logos */}
					<div className="flex gap-5">
						<SocialsIcon name="ion:logo-facebook" to="" />
						<SocialsIcon name="ion:logo-twitter" to="" />
						<SocialsIcon name="ion:logo-linkedin" to="" />
					</div>

					{/* Rights & madeby */}
					<div className="flex flex-col items-center jusify-center text-sm md:flex-row gap-5 text-tx-primary">
						<Link className="px-2 flex flex-row gap-1.5 items-center hover:bg-blue-100 rounded-full"
							to="/meetdevelopers"
						>
							Made with
							<div className="animate-bounce ">
								{" 🧡 "}
							</div>

							{"by "}
							<span className="text-br-primary font-bold " 								>
								IEEE CS
							</span>
						</Link>
						<span>Terms of Service</span>
						<span>© 2023 All rights reserved</span>
					</div>

				</div>

			</div>
		</footer>
	);
};
export default Footer;
