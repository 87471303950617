import React, { useState, useEffect } from 'react';
import tourismImage from '../../assets/Places/Tourism_Page.jpg';
import Kalaburagi from '../../assets/Places/Kalaburagi_image.png';
import BahamaniFort from '../../assets/Places/bahamani_fort.jpg';
import BuddhaVihar from '../../assets/Places/buddha_vihar.jpg';
import { Icon } from '@iconify/react';

const images = [
  { src: tourismImage, caption: 'Discover Kalaburagi', description: 'Known for its history and natural beauty' },
  { src: Kalaburagi, caption: 'Sharana Basaveshwara Temple', description: 'Houses the Samadhi of Sharana Basaveshwara at the center called the garbha gudi' },
  { src: BahamaniFort, caption: 'Bahamani Fort', description: 'Unique structure built in Persian architectural style, fully enclosed, with elegant domes and arched columns' },
  { src: BuddhaVihar, caption: 'Buddha Vihar', description: 'Houses the Samadhi of Sharana Basaveshwara at the center called the garbha gudi' },
];

const Carousel = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const goToPrevious = () => {
    const isFirstSlide = currentIndex === 0;
    const newIndex = isFirstSlide ? images.length - 1 : currentIndex - 1;
    setCurrentIndex(newIndex);
  };

  const goToNext = () => {
    const isLastSlide = currentIndex === images.length - 1;
    const newIndex = isLastSlide ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      goToNext();
    }, 5000); // Change the interval time as needed (5000ms = 5 seconds)
    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, [currentIndex]);

  return (
    <div className="relative w-full max-w-screen mx-auto">
      <div className="relative h-[400px] md:h-[600px] overflow-hidden">
        {images.map((image, index) => (
          <div
            key={index}
            className={`absolute inset-0 font-sans transition-opacity duration-500 ${index === currentIndex ? 'opacity-100' : 'opacity-0'}`}
          >
            <img src={image.src} alt={image.caption} className="w-full h-full object-cover" />
            <div className="absolute inset-0 bg-black bg-opacity-50 flex flex-col justify-center items-center text-center p-4">
              <h2 className="text-2xl w-10/12 md:text-3xl font-sans text-white font-semibold">{image.caption}</h2>
              <p className="text-white text-lg font-sans mt-3 w-10/12 md:w-1/2">{image.description}</p>
            </div>
          </div>
        ))}
      </div>
      <button
        onClick={goToPrevious}
        className="absolute top-1/2 left-[2%] transform -translate-y-1/2 text-black p-2 rounded-full shadow-lg"
      >
        <Icon icon="mdi:chevron-left" className='text-xl text-white' />
      </button>
      <button
        onClick={goToNext}
        className="absolute top-1/2 right-[2%] transform -translate-y-1/2 text-black p-2 rounded-full shadow-lg"
      >
        <Icon icon="mdi:chevron-right" className='text-xl text-white' />
      </button>
    </div>
  );
};

export default Carousel;
