import React, { useState, useEffect } from "react";
import Bgm from "../../assets/blog.svg";
import grad from "../../assets/Gradients/grad10.png";
import { client } from "../../Sanity/sanity-client";
import imageUrlBuilder from "@sanity/image-url";
import Intro from "../Intro";
import { Link } from "react-router-dom";

const builder = imageUrlBuilder(client);
function urlFor(source) {
  return builder.image(source);
}

const Card = (props) => {
  return (
    <Link
      className="max-w-1/3 w-full border md:border-2 border-stone-200 font-sans overflow-hidden rounded md:rounded-lg hover:scale-95 hover:border-br-primary"
      to={`/blogs/${props.slug}`}
    >
      <img
        className="w-full object-cover h-32 md:h-40"
        src={props.source}
        alt="cover Image" />

      <div className="flex bg-sf-secondary h-full flex-col p-4 md:p-6 pb-8 gap-2.5">
        <div className="font-medium text-base md:text-lg text-br-primary ">{props.title}</div>
        <p className="text-tx-secondary text-sm md:text-base leading-relaxed line-clamp-3 no-underline">{props.description}</p>
      </div>

    </Link >
  );
}

// needs to be removed
const BgmPic = () => {
  return (
    <div className="relative">
      <img src={Bgm} alt="" className="w-screen h-80 md:h-auto lg:h-80" />
      <div
        className="absolute space-y-2 md:space-y-4 lg:space-y-6 top-1/3 left-1/4 transform -translate-y-1/2 text-black"
        style={{ marginLeft: "-20%", marginTop: "90px" }}
      >
        <h2 className="text-xl text-blue-800 sm:text-2xl md:text-4xl lg:text-5xl font-vietnam mb-1 md:mb-4">
          Kalaburagi Blog
        </h2>
        <p className="text-xs md:text-xs lg:text-base font-vietnam font-medium tracking-tighter md:tracking-widest mb-2">
          Stay up-to-date with our latest blogs offering travel tips and
          insights. Don't miss out on our diverse range of upcoming events, from
          workshops to cultural experiences.
          <br /> Discover more and enhance your airport journey with valuable
          content and memorable activities.
        </p>
      </div>
    </div>
  );
};

const AllBlogs = () => {

  const [cardDetails, SetCardDetails] = useState([]);
  const [tourismCardDets, setTourismCardDets] = useState([]);
  useEffect(() => {
    client
      .fetch(
        `*[_type=="post" && "Kalaburagi Airport" in categories[]->title] | order(publishedAt desc){
          _id,
          title,
          slug,
          mainImage,
          body,
          blogBrief
          }`
      )
      .then((data) => SetCardDetails(data))
      .catch(console.error);
  }, []);
  useEffect(() => {
    client
      .fetch(
        `*[_type=="post" && "Explore Kalaburagi" in categories[]->title] | order(publishedAt desc){
          _id,
          title,
          slug,
          mainImage,
          body,
          blogBrief
          }`
      )
      .then((data) => setTourismCardDets(data))
      .catch(console.error);
  }, []);

  return (
    <div className="overflow-x-hidden">

      <Intro
        image={grad}
        title="Kalaburagi Blogs"
        description="Discover the latest updates on our upcoming events, blogs and activities. Stay up-to-date with our latest travel tips and insights."
      />

      <div className="  w-10/12 md:w-9/12 mx-auto flex flex-col gap-6 md:gap-16 py-16 md:py-24">

        <h2 className="text-lg md:text-2xl bg-br-primary p-3 text-white font-medium font-sans text-center rounded md:rounded-md">
          Airport Blogs
        </h2>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-6 md:gap-10 justify-center w-full">
          {cardDetails.map((item, index) => {
            let description;
            if (item.blogBrief && item.blogBrief[0]) {
              description = item.blogBrief[0].children[0].text;
            } else if (item.body && item.body[0]) {
              description = item.body[0].children[0].text;
            }

            return (
              <div key={index} className="flex justify-center">
                <Card
                  source={urlFor(item.mainImage).url()}
                  title={item.title}
                  description={description}
                  slug={item.slug.current}
                />
              </div>
            );
          })}
        </div>

        <h2 className="text-lg md:text-2xl bg-br-primary p-3 text-white font-medium font-sans text-center rounded md:rounded-md">
          Kalaburagi Blogs
        </h2>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-6 md:gap-10 justify-center w-full">
          {
            tourismCardDets.map((item) => {
              let description;
              if (item.blogBrief && item.blogBrief[0]) {
                description = item.blogBrief[0].children[0].text;
              } else if (item.body && item.body[0]) {
                description = item.body[0].children[0].text;
              }

              return (
                <Card
                  source={urlFor(item.mainImage).url()}
                  title={item.title}
                  description={description}
                  slug={item.slug.current}
                />
              );
            })
          }
        </div>

      </div>
    </div>
  );
};

export default AllBlogs;
