import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { client, getBlogDetails } from "../../Sanity/sanity-client";
import imageUrlBuilder from "@sanity/image-url";

const builder = imageUrlBuilder(client);
function urlFor(source) {
  return builder.image(source);
}

const BlogPage = () => {
  const [posts, setPosts] = useState([]);
  const { slug } = useParams();

  useEffect(() => {
    client
      .fetch(
        `*[slug.current == "${slug}"]{
          _id,
          title,
          slug,
          author-> {
            _id,
            name
          },
          mainImage,
          categories[]-> {
            _id,
            title
          },
          publishedAt,
          body
        }`
      )
      .then((data) => {
        setPosts(data);
      })
      .catch(console.error);
  }, [slug]);

  const [allDescription, setAllDescription] = useState("");

  // useEffect(() => {
  //   if (posts.length > 0) {
  //     const combinedDescriptions = posts[0].body[0].children
  //       .map((desc) => desc.text)
  //       .join("");
  //     setAllDescription(combinedDescriptions);
  //   }
  // }, [posts]);

  const renderBlock = (block, index) => {
    if (block._type === "block" && block.children) {
      let link = null;
      let linkKey = null;

      if (block.markDefs && block.markDefs.length > 0 && block.markDefs[0]._type === "link") {
        link = block.markDefs[0].href;
        linkKey = block.markDefs[0]._key;
      }

      return block.children.map((child, childIndex) => {
        if (child._type === "span") {
          if (child.marks && child.marks.length > 0) {
            if (child.marks.includes("strong")) {
              return (
                <div key={child._key}>
                  <b>{child.text}</b>
                  <br />
                </div>
              );
            } else if (child.marks.includes(linkKey)) {
              return (
                <div key={child._key}>
                  <Link to={link} target="_blank">
                    {child.text}
                  </Link>
                </div>
              );
            }
          }

          return (
            <div key={child._key}>
              {child.text}
              <br />
            </div>
          );
        }

        return null;
      });
    }

    if (block._type === "image") {
      const imageUrl = urlFor(block).url();
      return (
        <img
          key={block._key}
          src={imageUrl}
          alt=""
          className="w-full max-h-96 z-0 object-scale-down py-8"
        />
      );
    }

    return null;
  };

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const data = await getBlogDetails();
  //       if (data && data.length > 0) {
  //         setPosts(data);
  //       } else {
  //         setPosts("No Data Found");
  //       }
  //     } catch (error) {
  //       console.error("Error fetching data from Sanity: ", error);
  //     }
  //   };

  //   fetchData();
  // }, []);

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "short", day: "numeric" };
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", options).toUpperCase();
  };

  //console.log("posts", posts);

  return (
    <div className="flex flex-col w-10/12 md:w-9/12 mx-auto py-10 mt-16 md:py-20 md:mt-20 ">
      {posts.length > 0 ? (
        <div className="flex flex-col gap-8 md:gap-12 font-sans">
          <div className="flex flex-col gap-3 bg-sf-secondary border border-stone-200 rounded p-5">

            <div className="flex justify-between">
              <h2 className="text-sm md:text-base text-tx-secondary">
                {posts[0].categories[0].title}
              </h2>
              <time className="text-sm md:text-base text-tx-secondary text-right">
                {formatDate(posts[0].publishedAt)}
              </time>
            </div>

            <h1 className="text-lg text-br-primary font-semibold md:text-2xl">
              {posts[0].title}
            </h1>

            <p className="text-sm md:text-base font-medium text-tx-secondary">
              By {posts[0].author.name}
            </p>
          </div>

          {/* image */}
          <img
            className=" w-full max-h-96 z-0 object-cover"
            src={urlFor(posts[0].mainImage).url()}
            alt=""
          />

          <div className="bg-white text-sm md:text-base font-sans leading-8 md:px-20">
            {posts[0].body.map((block, index) => (
              <React.Fragment key={index}>
                {renderBlock(block, index)}
              </React.Fragment>
            ))}
          </div>
        </div>
      ) : (
        <p>Loading ...</p>
      )}
    </div>

  );
};



const BlogCard = ({ imageurl, heading, data }) => {
  return (
    <div className="max-w-xs border-2 border-black overflow-hidden shadow-[5px_5px_black] hover:shadow">
      <img className="w-full" src={imageurl} alt="" />
      <div className="px-6 py-4">
        <div className="font-bold text-xl mb-2">{heading}</div>
        <p className="text-gray-700 text-base">{data}</p>
      </div>
      <div className=" z-20 px-6 pt-4 pb-2">
        <div className=" text-blue-500 font-vietnam text-right">
          find out more
        </div>
      </div>
    </div>
  );
};

export default BlogPage;
