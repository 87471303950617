import React from "react";
import { Link } from "react-router-dom";

const Tourism = (props) => {
	return (
		<Link
			className="flex flex-col w-[300px] border-2 border-black rounded-xl font-sans overflow-hidden shadow-[4px_4px_black] hover:shadow"
			to={`/blogs/${props.slug}`}
		>
			<img
				className="w-full object-cover h-44"
				src={props.source}
				alt="cover Image" />

			<div className="flex flex-col p-6 h-full justify-between bg-sf-secondary no-underline">

				<div className="flex flex-col gap-2.5">
					<h2 className="font-semibold leading-relaxed text-lg text-br-primary no-underline">{props.title}</h2>
					<p className="text-tx-secondary leading-relaxed line-clamp-4 no-underline">{props.description}</p>
				</div>

				{/* <p className="text-br-primary text-right text-sm font-semibold no-underline mt-2">Read More</p> */}
			</div>

		</Link >
	);
};

export default Tourism;
