import React from "react";

const BusCard = (props) => {
  const { from, to, frequency, timing } = props;
  return (
    <div className="relative w-full justify-center h-auto font-sans overflow-hidden
      border-stone-300 border rounded-xl flex flex-col bg-sf-secondary">

      <div className="relative flex flex-row items-center justify-between border-b border-stone-300 gap-10 p-4 py-3">
        <h3 className="font-semibold text-sm md:text-base text-tx-primary">
          {from}
        </h3>
        <p className="text-stone-400 text-base">
          to
        </p>
        <h3 className="font-semibold text-sm md:text-base text-br-primary">
          {to}
        </h3>
      </div>


      <div className="flex w-full gap-3 p-4 capitalize flex-row flex-wrap">
        {timing.map((time, index) => (
          <p
            key={index}
            className="px-2 py-1 rounded-lg text-tx-secondary border bg-sf-primary border-stone-300 
              w-fit text-sm font-semibold">
            {time}
          </p>
        ))}
      </div>
    </div>
  );
};

export default BusCard;
