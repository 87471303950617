import React from "react";
import Airport from "../../assets/Images/Airport.png";
import { Link } from "react-router-dom";
import { Icon } from "@iconify/react/dist/iconify.js";

// rename
const Hero = () => {
	return (
		<section className="relative overflow-x-hidden">

			<div className="absolute top-0 left-0 w-full z-[-1] h-full">
				<img src={Airport} alt="" className="w-full h-full object-cover" />
				<div className="absolute w-full top-0 left-0 w-full h-full bg-gradient-to-b from-black via-transparent to-black opacity-5" />
			</div>

			<div className=" flex flex-col gap-6 pt-32 p-10 md:py-60 md:px-32">

				{/* Hero Text */}
				<div className="flex flex-col gap-2 md:gap-3">

					<h2 className="text-4xl lg:text-6xl text-br-primary font-elsie leading-tight md:leading-loose">
						Welcome to
						<br />
						Kalaburagi Airport
					</h2>
					<p className="text-sm lg:text-lg font-sans text-tx-primary">
						More than just an airport. An experience.
					</p>
				</div>

				{/* buttons */}
				<div className="flex flex-col md:flex-row justify-start gap-2 md:gap-4">

					<button className="flex items-center justify-center bg-br-primary hover:bg-blue-800 text-white font-sans p-3 
                        md:px-5 md:py-3 text-sm md:text-base rounded-lg flex items-center gap-2 md:gap-2.5 text-sm">

						<Icon icon="ion:airplane" className='text-xl md:text-2xl' />
						<Link to={"/flights"} className="text-white no-underline">
							<span>Find Your Flight</span>
						</Link>

					</button>

					<button className="flex items-center justify-center border-2 bg-br-primary/5 border-br-primary hover:bg-blue-100 text-br-pr 
                        font-sans p-3 md:px-4 md:py-2 text-sm md:text-base rounded-lg flex items-center gap-2 md:gap-2.5 text-sm">

						<Icon icon="ion:compass" className='text-xl md:text-2xl text-br-primary' />
						<Link to={"/discover"} className="font-medium text-br-primary no-underline">
							<span>Explore</span>
						</Link>
					</button>

				</div>

			</div>
		</section>
	);
};

export default Hero;
