import React, { useState } from "react";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import BusCard from "./BusCard";
import BusData from "../jsonData/BusSchedule.json";
import { Link } from "react-router-dom";
import { Icon } from "@iconify/react/dist/iconify.js";

const BusSection = () => {
  const [page, setPage] = useState(1); // Current page
  const itemsPerPage = 3; // Number of items per page

  const startIndex = (page - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const openGoogleMaps = (link) => {
    window.open(link, "_blank");
  };
  const handleChangePage = (event, value) => {
    setPage(value);
  };

  return (
    <div className="flex flex-col items-center  w-full py-12 gap-12">


      {/* Note card */}
      <div className="flex flex-col md:flex-row text-tx-primary w-full md:w-[80%] font-sans md:items-center justify-between gap-6 
        border border-stone-200 p-4 rounded-3xl bg-sf-secondary">
        <div className="bg-slate-200 w-fit p-3 rounded-2xl border border-stone-200">
          <Icon icon="ion:footsteps" className="text-2xl text-br-primary" />
        </div>
        <p>
          <span className="font-semibold">Note:</span> The Distance Between Kalaburagi Airport and Bus Stand is
          Approximately <b className="text-br-primary">16 km</b>. To get location
        </p>
        <button
          onClick={() =>
            openGoogleMaps(
              "https://www.google.com/maps/dir/Kalaburagi+Airport,+Kalaburagi+Airport+Road,+Srinivas+Saradgi,+Karnataka/Central+Bus+Stand+Kalaburagi,+8RG9%2BM58,+Central+Bus+Stand,+Godutai+Nagar,+Kalaburagi,+Karnataka+585101/@17.3183114,76.8444614,13z/data=!3m1!4b1!4m13!4m12!1m5!1m1!1s0x3bc8bf9b577dd5e9:0x37ace0c5682df26d!2m2!1d76.9533712!2d17.310407!1m5!1m1!1s0x3bc8b8acfdcb833b:0xe25b18429992424f!2m2!1d76.8180677!2d17.3266772?entry=ttu"
            )
          }
          className="text-br-primary text-sm hover:text-slate-600 uppercase font-semibold"
        >
          Click Here
        </button>
      </div>

      <div className="flex flex-col w-full gap-10">

        <div className="flex flex-row w-full items-end justify-between border-b-2 pb-5 border-stone-200">

          <h2 className="font-sans font-semibold text-br-primary text-xl">Available Buses</h2>

          <Stack direction="row" spacing={2} justifyContent="center">
            <Pagination
              variant="outlined"
              shape="rounded"
              count={Math.ceil(BusData.length / itemsPerPage)}
              page={page}
              onChange={handleChangePage}
            />
          </Stack>

        </div>

        <div className="flex flex-col w-full gap-5">
          {BusData.slice(startIndex, endIndex).map((busItem, index) => (
            <BusCard
              key={index}
              from={busItem.from}
              to={busItem.to}
              frequency={busItem.frequency}
              timing={busItem.timing}
            />
          ))}
        </div>

        <Stack direction="row" spacing={2} justifyContent="center">
          <Pagination
            variant="outlined"
            shape="rounded"
            count={Math.ceil(BusData.length / itemsPerPage)}
            page={page}
            onChange={handleChangePage}
          />
        </Stack>
      </div>

      <button className="flex flex-row w-fit gap-3 bg-black text-white font-sans px-5 py-3 rounded-xl hover:scale-105">
        <a href="https://ksrtc.in/oprs-web/guest/home.do" target="_blank" style={{ textDecoration: "none", color: "white" }}>
          Book on KKRTC
        </a>
        <Icon icon="ion:arrow-up-right-box" className="text-lg" />
      </button>

      {/* <Link to="https://ksrtc.in/oprs-web/guest/home.do">
      </Link> */}
    </div>
  );
};

export default BusSection;
