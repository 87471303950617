import React, { useEffect, useState } from "react";
import { Icon } from "@iconify/react/dist/iconify.js";
import { client } from "../../../Sanity/sanity-client";


const Leaders = [
  {
    card_title: "Airport Director",
    title: "Director",
    photo: "",
    description: "The director is an expert in aviation systems with a strong educational foundation, including degrees in Electronics and Communication Engineering, Systems & Signal Processing, and Air Navigation, culminating in a Ph.D. His career spans various technical and managerial roles in radio navigational stations and airport management. Currently, he is the Airport Director of Kalaburagi Airport. His research focuses on navigation, CNS/ATM systems, where he has published work on using machine learning to optimize air traffic management and enhance aviation safety.",
  },
];

const LeaderCard = ({ leader }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const handleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const [directorInfo, setDirectorInfo] = useState();
  useEffect(() =>{
    client.fetch(
      `*[_type == "directorInfo"][0]{
        title,
        subtitle,
        body,
        }`
    ).then((data) =>{
      setDirectorInfo(data);
      // console.log(data);
    })
  }, [])


    const renderBody = (body) => {
      return body.map((block) => {
        return (
          <p key={block._key} className="text-tx-primary leading-relaxed">
            {block.children.map((child) => child.text).join(" ")}
          </p>
        );
      });
    };
  

  return (
    <div className="flex flex-col bg-sf-secondary w-full border border-stone-200 rounded overflow-hidden">
      <div
        className={`${isExpanded ? "bg-indigo-100" : "bg-sf-secondary"
          } w-full flex flex-row items-center px-8 py-6 gap-3 justify-between cursor-pointer`}
        onClick={handleExpand}
      >
        <h2 className="text-br-primary font-medium text-lg font-vietnam">{directorInfo?.title}</h2>
        {
          isExpanded ?
            <Icon icon={"ion:minus"} className="text-lg text-br-primary" /> :
            <Icon icon={"ion:plus"} className="text-lg text-tx-primary" />
        }
      </div>
      {isExpanded && (
        <div className="flex flex-col md:flex-row p-8 justify-center items-start gap-8">
          {
            leader.photo ? <img src={leader.photo} className="w-full max-h-56 md:w-[30%] object-cover"/> : null
          }
          <div className="w-full flex flex-col gap-3">
            <h2 className="text-br-primary font-medium text-lg">{directorInfo?.subtitle}</h2>
            <p className="text-tx-primary leading-relaxed">{renderBody(directorInfo.body)}</p>
          </div>
        </div>
      )}
    </div>
  );
};

const LeadershipPage = () => {
  return (
    <div className="flex flex-col font-sans items-center justify-center gap-8">

      <div className="flex flex-col gap-2 w-fit items-center text-center">
        <div className="text-lg text-tx-primary font-semibold md:text-xl">
          Our Leaders
        </div>
        <div className="h-0.5 w-10/12 bg-br-primary rouded-full"></div>
      </div>

      {Leaders.map((leader, index) => (
        <LeaderCard key={index} leader={leader} />
      ))}
    </div>
  );
};

export default LeadershipPage;
