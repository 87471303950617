import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { client } from "../../Sanity/sanity-client.js";
import imageUrlBuilder from "@sanity/image-url";

import Caraousel from "./caraousel.js";
import DiscoverCard from "./discoverCard.js";
import HotelCard from "./hotelCard.js";

const builder = imageUrlBuilder(client);
function urlFor(source) {
	return builder.image(source);

}

// const locations = [
//   {
//     photo: Kalaburagi,
//     title: "Sharana Basaveshwara Temple",
//     description:
//       "The temple is dedicated to an eminent Lingayat religious teacher and philosopher, Shri Sharana Basaveshwara,a Lingayat saint of the 18th century known for his Dasoha (Giving is earning) and Kayaka — an advancement of the Karma doctrine 'You have the right to perform the work assigned to you. You have no rights to 'demand' the fruits of your labor' philosophy. The temple houses the Samadhi of Sharana Basaveshwara at the center called the garbha gudi. It also has a lake adjacent to it which attracts many devotees and tourists",
//     link: "https://maps.app.goo.gl/Jvyt7yECWS9QdA7v7",
//   },
//   {
//     photo: kalagi,
//     title: "Kalagi-Kaleshwara Temple",
//     description:
//       "The 2nd century Kaleshwara temple becomes cynosure of all the eyes during the important VeeraShaiva festivals, particularly Mahashivaratri and thousands of devout hindus throng the temple to have the darshan of the ancient linga installed inside the once beautiful temple.But in the eyes of the Archeological Survery of India (ASI) or the State Archeology Department, this temple, considered to be one of the ancient Hindu temples is a non existent once.",
//     link: "https://maps.app.goo.gl/LquXVBz3xgLT7MdCA",
//   },
//   {
//     photo: Malkheda,
//     title: "Malkhed Fort",
//     description:
//       "Malkhed is a place of historical importance situated around 40 km south-east of Gulbarga, on the left bank of Kagina river, a tributary of the Bhima River. Earlier known as Manyakheta, it is also believed to be the capital of Rashtrakuta kings. Malkhed today has the remains of a fort with four entrances and 52 bastions. And in the village, there is an ancient Mallinatha Basadi, which consists of a garbhagriha, a navaranga and a hazara. Some 59 manuscripts in Prakrit, Sanskrit and Kannada languages have been found in this Basadi.",
//     link: "https://maps.app.goo.gl/tC9jWmjeYHFZtXP66",
//   },
// ];

const hotels = [
	{
		title: "SR Continental",
		address: "15.5 km from airport",
		contact: " ",
		link: "https://maps.app.goo.gl/fqxYSZ1dgWkc2fce8",
	},
	{
		title: "Zest Club",
		address: "15.9 km from airport ",
		contact: " ",
		link: "https://maps.app.goo.gl/8UFG1c2Pc1KJpGhq5",
	},
	{
		title: "Hotel Heritage INN",
		address: "14.7 km from airport",
		contact: " ",
		link: "https://maps.app.goo.gl/YYy3a1g1LUhS9VzJA",
	},
];

const Discover = () => {

	// data fetching from sanity
	const [discoverTourism, setDiscoverTourism] = useState([]);
	useEffect(() => {
		client
			.fetch(
				`*[_type == "discoverTourism"]  {
			title,
			mainImage,
			body,
			link,
			rating
			}`
			)
			.then((data) => {
				setDiscoverTourism(data);
				// console.log(data);
			});
	}, []);

	return (
		<>
			<Caraousel />

			{/* Places  */}
			<section className="flex flex-col font-sans gap-12 items-center py-16">
				<h2 className="text-xl md:text-2xl font-medium text-left font-sans text-br-primary">
					Gems of Kalaburagi 💎
				</h2>

				<div className="flex items-center justify-center flex-col gap-5 md:gap-10 ">
					{discoverTourism.map((place, index) => (
						<DiscoverCard
							key={index}
							source={urlFor(place.mainImage).url()}
							description={place.body[0].children[0].text}
							title={place.title}
							link={place.link}
						/>
					))}
				</div>
			</section>

			{/* Hotels */}
			<section className="flex flex-col font-sans gap-12 items-center py-20">
				<h2 className="text-xl md:text-2xl font-medium text-left font-sans text-br-primary">
					Hotels Near Kalaburagi Airport
				</h2>

				<div className="flex max-md:flex-col gap-5 md:gap-10 w-full md:flex-wrap md:w-8/12">
					{hotels.map((hotel, index) => (
						<HotelCard hotel={hotel} key={index} />
					))}
				</div>

				<div>
					For more such hotels, click on this link: 
					<Link className="text-blue-500" target="_blank" to={"https://www.google.com/search?q=Hotels+in+kalaburagi+&sca_esv=d344c84f40fd38a2&sca_upv=1&sxsrf=ADLYWILrasJlqw5Qx0tHXC2-GEaEcLBoBA%3A1727012858429&ei=-h_wZvb0GdSKseMPnsfKqQg&ved=0ahUKEwj22cuf2NaIAxVURWwGHZ6jMoUQ4dUDCA8&uact=5&oq=Hotels+in+kalaburagi+&gs_lp=Egxnd3Mtd2l6LXNlcnAiFUhvdGVscyBpbiBrYWxhYnVyYWdpIDIFEAAYgAQyBhAAGBYYHjIGEAAYFhgeMgsQABiABBiGAxiKBTILEAAYgAQYhgMYigUyCxAAGIAEGIYDGIoFMggQABiABBiiBDIIEAAYgAQYogRI_yFQkwVYkB1wAngAkAEAmAG4AaAB8gOqAQMwLjO4AQPIAQD4AQGYAgKgAsgCmAMAiAYBkgcDMC4yoAeWCw&sclient=gws-wiz-serp"}> here</Link>
				</div>
			</section >



		</>
	);
};

export default Discover;
