import React from "react";
import { Link } from "react-router-dom";
import { Icon } from "@iconify/react/dist/iconify.js";

// todo: need to upadate missing contact information
const contactData = [
  {
    title: "Contact Us",
    items: [
      {
        label: "Address",
        value: "8X63+589, Kalaburagi Airport Rd, Srinivas Saradgi, Karnataka 585102",
      },
      {
        label: "Phone",
        value: "011 2463 2950",
        icon: "ion:call"
      },
      {
        label: "Email",
        value: "Email",
        icon: "ion:mail"
      }
    ]
  },
  {
    title: "Helplines",
    items: [
      {
        label: "To Request special assistance, reach out to",
        value: "Contact/ Email",
        icon: "ion:help-circle"
      },
      {
        label: "Police Station/Fire Station",
        value: "Police:08472228112\nFSO:08212540970",
        icon: "ion:call"
      },
      {
        label: "Medical Emergency",
        value: "ESI:08472265548/GIMS:08472291252\nUnited:0847225006",
        icon: "ion:call"
      }
    ]
  },
  {
    title: "Authority",
    items: [
      {
        label: "Airport Director",
        values: [
          { value: "08472 295251", icon: "ion:call" },
          { value: "apd_vogb@aai.aero", icon: "ion:mail" }
        ]
      },
      {
        label: "PGO",
        values: [
          { value: "08472 295049", icon: "ion:call" },
          { value: "pgo_vogb@aai.aero", icon: "ion:mail" }
        ]
      },
      {
        label: "Safety Manger",
        values: [
          { value: "08472-295044", icon: "ion:call" },
          { value: "", icon: "ion:mail" }
        ]
      }
    ]
  },
  {
    title: "Other helplines",
    items: [
      {
        label: "Alliance Air Airline",
        values: [
          { value: "08472-295634", icon: "ion:call" },
          { value: "mail", icon: "ion:mail" }
        ]
      },
      {
        label: "Star Air Airline",
        values: [
          { value: "08472-295309", icon: "ion:call" },
          { value: "pgo_vogb@aai.aero", icon: "ion:mail" }
        ]
      },
      {
        label: "For feedback, reach out to",
        value: <Link className="text-br-primary" target="_blank" to={"https://forms.gle/6CR9vG9KU9948o1h8"}>Click here</Link>,
        icon: "ion:paper-plane"
      },
      {
        label: "Contact Developers",
        value: <Link className="text-br-primary" to="/meetdevelopers">Developers Page</Link>,
        icon: "ion:code-slash"
      }
    ]
  }
];


const ContactCard = ({ title, items }) => {
  return (
    <div className="bg-sf-secondary p-6 rounded-lg w-full md:w-[45%] font-sans border border-stone-200 flex gap-5 flex-col justify-start">
      <h2 className="text-lg font-semibold uppercase text-br-primary">{title}</h2>
      <div className="flex flex-col gap-y-4">
        {items.map((item, index) => (
          <div key={index} className="flex flex-col gap-3">
            <h3 className="text-base font-semibold text-tx-primary">{item.label}</h3>
            {item.values ? (
              item.values.map((subItem, subIndex) => (
                <div key={subIndex} className="flex flex-row gap-2 items-center">
                  {subItem.icon && (
                    <Icon icon={subItem.icon} className="text-base text-tx-primary" />
                  )}
                  <p className="text-tx-secondary font-medium leading-relaxed">{subItem.value}</p>
                </div>
              ))
            ) : (
              <div className="flex flex-row gap-2 items-center">
                {item.icon && (
                  <Icon icon={item.icon} className="text-base text-tx-primary" />
                )}
                <p className="text-tx-secondary font-medium leading-relaxed">{item.value}</p>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};




const ContactPage = () => {
  return (
    <div className="flex flex-col font-sans items-center justify-center gap-8">

      <div className="flex flex-col gap-2 w-fit items-center text-center">
        <div className="text-lg text-tx-primary font-semibold md:text-xl">
          Contact Information
        </div>
        <div className="h-0.5 w-10/12 bg-br-primary rouded-full"></div>
      </div>

      <div className="flex w-full justify-center flex-col md:flex-row flex-wrap gap-6 md:gap-10">
        {contactData.map((section, index) => (
          <ContactCard key={index} title={section.title} items={section.items} />
        ))}
      </div>
      
    </div>
  );
};

export default ContactPage;
