import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Hero from "./Hero.js";
import { Icon } from "@iconify/react/dist/iconify.js";
import { getBlogCardDetails } from "../../Sanity/sanity-client.js";
import Globe from "../../assets/globe.js";
import InfiniteScroller from "../Scroller/InfiniteScrollerTourism.js";
import InfiniteScrollerExplore from "../Scroller/InfiniteScrollerExplore.js";
import { client } from "../../Sanity/sanity-client.js";
import imageUrlBuilder from "@sanity/image-url";
import BlogCard from "../BlogCard.js";

const builder = imageUrlBuilder(client);
function urlFor(source) {
	return builder.image(source);
}

const Landing = () => {

	const [cardDetails, SetCardDetails] = useState([]);

	useEffect(() => {
		client
			.fetch(
				`*[_type=="post" && "Kalaburagi Airport" in categories[]->title] | order(publishedAt desc){
					_id,
					title,
					slug,
					mainImage,
					body,
					blogBrief
					}`
			)
			.then((data) => SetCardDetails(data))
			.catch(console.error);
	}, []);

	return (
		<div>

			<Hero />

			{/* Let's get started */}
			<section className="flex flex-col justify-center items-center w-full py-8 md:py-16 gap-4 md:gap-8 bg-dot-grid bg-[length:35px_35px]">

				<h2 className="text-lg md:text-2xl font-medium text-left font-sans text-br-primary">
					Let's get started!
				</h2>

				<div className="flex flex-col md:flex-row gap-4 md:gap-2.5 justify-center items-center w-5/6 md:w-2/6">
					<div className="flex flex-row flex-1 w-full bg-sf-secondary p-3 md:px-3 md:py-2 rounded-lg gap-3 border">
						<Icon icon="fluent:city-16-filled" className='text-xl md:text-3xl text-br-primary' />
						<input
							type="text"
							id="custom-text-input"
							name="name"
							className="bg-sf-secondary rounded-lg text-sm md:text-base text-tx-primary w-full focus:outline-none "
							placeholder='Your City'
						/>
					</div>

					<Link className="no-underline" to={"/flights"}>
						<button
							className="bg-br-primary text-white hover:bg-blue-800 font-sans p-2.5 border border-br-primary
														md:px-5 md:py-3 rounded-lg tracking-wide flex items-center gap-2 md:gap-3 text-sm md:text-base"
						>
							<Icon icon="ion:airplane" className='text-xl text-white' />
							Find my Flight!
						</button>
					</Link>
				</div>
			</section>

			{/* State of the art features */}
			<section className="overflow-hidden py-8 md:py-20 relative flex flex-col  gap-8 md:gap-12 justify-center items-center">

				<h2 className="text-xl md:text-2xl font-medium text-left font-sans text-br-primary">
					State of the Art features ✨
				</h2>

				<div className="flex text-lg sm:text-2xl flex-col lg:flex-row font-sans">

					<div className="flex flex-col bg-br-primary p-8 gap-5 text-white flex-1 md:min-w-1/3">
						<p className="text-base md:text-lg font-medium">
							Smart Parking System
						</p>
						<p className='text-base leading-relaxed'>
							Streamline with our smart parking system. Find available spaces
							effortlessly and enjoy automated payment options.
						</p>
					</div>

					<div className="flex flex-col bg-sf-secondary p-8 gap-5 text-br-primary flex-1 md:min-w-1/3">
						<p className=" text-base md:text-lg font-medium">
							Accurate estimation of flight arrival time
						</p>
						<p className='text-base leading-relaxed'>

							We strive to provide you with the most precise and up-to-date
							information on when your flight is expected to land. This ensures
							that you can plan your journey with confidence and stay informed
							every step of the way.
						</p>
					</div>

					<div className="flex flex-col bg-br-primary p-8 gap-5 text-white flex-1 md:min-w-1/3">
						<p className=" text-base md:text-lg font-medium">
							Tourism in Kalaburagi
						</p>
						<p className='text-base leading-relaxed'>

							Kalaburagi, a city steeped in history and culture, offers a diverse
							range of attractions for travelers to explore. From ancient
							fortresses to vibrant markets, we provide a glimpse of what this
							fascinating destination has to offer
						</p>

					</div>

					<div className="flex flex-col bg-sf-secondary p-8 gap-5 text-br-primary flex-1 md:min-w-1/3">
						<p className="text-base md:text-lg font-medium">
							Moving in and around Kalaburagi
						</p>
						<p className='text-base leading-relaxed'>

							When exploring the beautiful city of Kalaburagi, you'll find a
							variety of transportation options and their schedules to help you
							navigate the area conveniently.
						</p>

					</div>

				</div>
			</section>

			{/* Explore Kalaburagi */}
			<section className="py-8 md:py-24 overflow-hidden relative flex flex-col gap-8 md:gap-12 justify-center items-center">

				<h2 className="text-xl md:text-2xl font-medium text-left font-sans text-br-primary">
					Explore Kalaburagi
				</h2>
				<InfiniteScroller />
			</section>

			{/* What's happening */}
			<section className="py-8 md:py-24 w-10/12 md:w-full mx-auto overflow-hidden relative flex flex-col gap-8 md:gap-12 justify-center items-center">
				<h2 className="text-xl md:text-2xl font-medium text-left font-sans text-br-primary">
					What's Happening?
				</h2>

				<div className="flex flex-col md:flex-row gap-14 p-30">

					{cardDetails.slice(0, 3).map((item) => {

						let description;

						if (item.blogBrief && item.blogBrief[0]) {
							description = item.blogBrief[0].children[0].text;
						} else if (item.body && item.body[0]) {
							description = item.body[0].children[0].text;
						}

						return (
							<BlogCard
								key={item.slug}
								source={urlFor(item.mainImage).url()}
								title={item.title}
								description={description} // Use the sliced description
								slug={item.slug.current}
							/>
						);
					})}

					{/* <InfiniteScrollerExplore /> */}
				</div>

				<Link className=" text-tx-secondary hover:bg-br-primary/10 font-medium rounded-lg hover:text-br-primary px-4 py-2 no-underline" to={"/blogs"}>
					Explore all blogs
				</Link>

				<div className="hidden md:inline-block z-0 absolute -bottom-10 right-0 ">
					<Globe />
				</div>
			</section>

		</div>
	);
};

export default Landing;
