import React from "react";
import MapImage from "../../../assets/Images/airport-map.png";
import { Link } from "react-router-dom";

const MapPage = () => {
  return (
    <div className="flex flex-col font-sans items-center justify-center gap-8">

      <div className="flex flex-col gap-2 w-fit items-center text-center">
        <div className="text-lg text-tx-primary font-semibold md:text-xl">
          Kalaburagi Airport, Karnataka
        </div>
        <div className="h-0.5 w-10/12 bg-br-primary rouded-full"></div>
      </div>

      <div className="flex w-full justify-center flex-col">
        <Link to={""}>
          <img
            className="h-full w-full"
            src={MapImage}
            alt="kalaburagi-airport"
          />
        </Link>
      </div>
      <div
        className="flex flex-col gap-3 p-8 rounded bg-sf-secondary border border-stone-200"
      >
        <p>16 Kms from Gulbarga/Kalaburagi Railway Station</p>
        <p>16 KMs from Gulbarga Bus Stand </p>
        <p>14.5 KMs from City center</p>
      </div>
    </div>
  );
};

export default MapPage;
